/** @format */

import styled from "styled-components";

const Container = styled.p`
  line-height: 50px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.secondary};
  font-family: "Noto Sans";
`;

const IndicatorPageText = (props) => {
  return <Container>{props.text}</Container>;
};

export default IndicatorPageText;

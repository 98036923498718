/** @format */

import InputTextLabel from "../../../molecules/inputs/input-text-label";
import { expresiones } from "../../../../helper/expresiones";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import styled from "styled-components";
import { useEffect, useState } from "react";

const Container = styled.div`
  > div {
    width: 30%;
  }
  @media (max-width: 840px) {
    > div {
      width: 100%;
    }
  }
`;
const TitlePublication = ({ publication, title }) => {
  const [form, setForm] = useState();
  useEffect(() => {
    if (publication) {
      setForm({
        ...form,
        title: {
          value: publication.title,
          validate: true,
        },
      });
    }
  }, [publication]);

  const handled = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };
  return (
    <>
      <SubtitleForm text={title} />
      <Container>
        <InputTextLabel
          label={{
            text: "Nombre de la empresa",
          }}
          input={{
            placeholder: "Nombre de la empresa",
            onChange: handled,
            message: form?.title?.message,
            value: form?.title?.value,
            name: "title",
          }}
        />
      </Container>
    </>
  );
};

export default TitlePublication;

import BasePublic from "../../organisms/bases/base-public";

import styled from "styled-components";

const About = styled.div`
    visibility: visible;
    animation-name: fadeInUp;
    font,
    .font {
        text-align: justify;
        font-family: "Helvetica", "Verdana", "Monaco", sans-serif;
    }
`;

const We = (props) => {
    return (
        <BasePublic title="Nosotros" carousel formsearch>
            <div id="about">
                <div className="container about-container wow fadeInUp">
                    <div className="row mb-5 mt-5">
                        <div className="col-md-12 about-content">
                            <p className="font">
                                Akunaipa.com tiene como misión visibilizar las
                                actividades y sitios turísticos rurales que por
                                diferentes motivos, hoy son desconocidos.
                            </p>

                            <p className="font">
                                Akunaipa.com promueve el turismo legal,
                                ambiental y socialmente responsable. Así como,
                                genera nuevas alternativas de ingresos a las
                                comunidades locales. De esta manera,
                                beneficiando a las comunidades anfitrionas,
                                conectando al turista con su destino, eliminando
                                intermediarios.
                            </p>

                            <p className="font">
                                Akunaipa.com de ninguna manera apoya el turismo
                                de drogas, abuso de menores ni mayores. Nos
                                acogemos a un código de ética y valores de
                                conducta, con el fin de proteger a los menores
                                de edad de todo tipo de explotación.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </BasePublic>
    );
};

export default We;

/** @format */

import styled from "styled-components";

const TitleCategorie = styled.h2`
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 12px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.secondary};
  @media (max-width: 840px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

const SubtitleCategorie = (props) => {
  return <TitleCategorie>{props.text}</TitleCategorie>;
};

export default SubtitleCategorie;

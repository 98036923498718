/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
const Title = styled.p`
  font-size: 12px;
`;
const Img = styled.img`
  margin: 0 auto;
  display: none;
`;
const CardService = (props) => {
  const service = props.data;
  return (
    <div key={service.id} className='col-lg-3 mb-2'>
      <div className='card'>
        <div className='card-header'>
          <row>
            <div className='col-md-10'>
              <Title className='text-capitalize'>{service.name}</Title>
              <Img
                src={service.img}
                alt={service.name}
                className='img-thumbnail rounded  mx-auto d-block'
              />
            </div>
            <div className='col-md-2'>
              <div className='badge bg-success'>
                {service.services_categories_count}
              </div>
            </div>
          </row>
        </div>
        <div className='row mt-2 mb-2'>
          <div className='col-6 col-lg-6'>
            <button
              className='btn btn-block btn-warning'
              onClick={() => props.actions.edit(service.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
          <div className='col-6 col-lg-6 '>
            <button
              className='btn btn-block btn-danger'
              onClick={() => props.actions.delete(service.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardService;

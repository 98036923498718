/** @format */

import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = (props) => {
  const { url, text } = props.item;
  return (
    <Link
      id='menu_b'
      to={url}
      className='list-group-item list-group-item-action '>
      {text}
    </Link>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Footer = styled.footer`
  width: 100%;
  height: 100%;
  background-color: #333;
  padding: 10px 0 0 10px;
  p {
    margin-bottom: 0;
  }
`;

const FooterPublic = (props) => {
  const items = [
    {
      id: 1,
      url: "/",
      text: "Inicio",
    },
    {
      id: 2,
      url: "/",
      text: "Preguntas Frecuentes ",
    },
    {
      id: 3,
      url: "/nosotros",
      text: "Nosotros",
    },
    {
      id: 4,
      url: "/contacto",
      text: "Contáctanos",
    },
  ];

  return (
    <Footer as='footer' className='mt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 '>
            <div id='menu_bajo'>
              <div id=''>
                {items.map((item) => (
                  <Item key={item.id} item={item} />
                ))}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div id='facebook'>
              <Iframe
                src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fakunaipa%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=true&amp;adapt_container_width=false&amp;hide_cover=true&amp;show_facepile=true&amp;appId=207438813822030'
                scrolling='no'
                frameborder='0'
                allowtransparency='true'
                allow='encrypted-media'></Iframe>
            </div>
          </div>
        </div>
        <div className=' text-center mt-5'>
          <p className='text-white'>
            Akunaipa © Turismo Rural | Desarrollo web:{" "}
            <a href='https://criptowebs.com' target='_white text-success'>
              Criptowebs
            </a>
          </p>
        </div>
      </div>
    </Footer>
  );
};

export default FooterPublic;

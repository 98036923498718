/** @format */

import React, { useState, useEffect } from "react";
import InputSelectLabel from "../../../molecules/inputs/input-select-label";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import { ContainerInputs } from "../style/styleForm";
import { getFetch } from "../../../../helper/fetchApi";
import addValueSelect from "../../../../helper/addValueSelect";
import { initSelect } from "../stateinitialforms/init-form-create-publication";
import styled from "styled-components";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  @media (max-width: 840px) {
    display: block;
  }
`;
const SegmentPlace = ({ publication = {}, title, create }) => {
  const [form, setForm] = useState();
  const [categories, setCategories] = useState(initSelect);
  const [subcategories, setSubcategories] = useState(initSelect);
  const [services, setServices] = useState(initSelect);

  useEffect(() => {
    if (Object.entries(publication).length !== 0) {
      const { categorie } = publication;

      getFetch("categories")
        .then((categories) => {
          const newCategories = addValueSelect(categories.data);
          setCategories({
            ...newCategories,
            value: {
              label: categorie?.parent_categorie?.name,
              value: categorie?.parent_categorie?.id,
            },
          });
          return categorie.parent_id;
        })
        .then((categorie_id) => {
          getFetch(`categories/${categorie_id}/subcategories`).then(
            (response) => {
              const newSubcategories = addValueSelect(response.data);
              setSubcategories(newSubcategories);
            },
          );

          getFetch(`categories/${categorie_id}/services`).then((response) => {
            const newServices = addValueSelect(response.data.service);
            const reducer = (acc, current) => {
              return [...acc, { label: current.name, value: current.id }];
            };
            const currentServices = publication.services.reduce(reducer, []);
            setServices({ ...newServices, value: currentServices });
          });
        });
    }
  }, [publication]);

  useEffect(() => {
    if (create) {
      getFetch("categories")
        .then((categories) => {
          const newCategories = addValueSelect(categories.data);
          setCategories(newCategories);
          return newCategories.options[0].value;
        })
        .then((categoryId) => {
          getFetch(`categories/${categoryId}/subcategories`).then(
            (response) => {
              const newSubcategories = addValueSelect(response.data);
              setSubcategories(newSubcategories);
            },
          );

          getFetch(`categories/${categoryId}/services`).then((response) => {
            const newServices = addValueSelect(response.data.service);
            setServices(newServices);
          });
        });
    }
  }, [create]);

  const handledChangeCategorie = (e, action) => {
    setCategories({
      ...categories,
      value: e,
    });

    getFetch(`categories/${e.value}/subcategories`).then((response) => {
      const newSubcategories = addValueSelect(response.data);
      setSubcategories(newSubcategories);
    });

    getFetch(`categories/${e.value}/services`).then((response) => {
      const newServices = addValueSelect(response.data.service, "name", "id");
      setServices(newServices);
    });
  };

  const handledChangeSubCategorie = (e, meta) => {
    setSubcategories({
      ...subcategories,
      value: { label: e.label, value: e.value },
    });
  };

  const handleService = (e, meta) => {
    setServices({
      ...services,
      value: e,
    });
  };

  return (
    <>
      <SubtitleForm text={title} />
      <Container>
        <InputSelectLabel
          label={{
            text: "Categoria",
          }}
          select={{
            ...categories,
            onChange: handledChangeCategorie,
            name: "categorie_id",
          }}
        />
        <InputSelectLabel
          label={{
            text: "Segmento",
          }}
          select={{
            ...subcategories,
            name: "subcategorie_id",
            onChange: handledChangeSubCategorie,
          }}
        />
      </Container>

      <SubtitleForm text='Servicios' />

      <InputSelectLabel
        label={{
          text: "Servicios",
        }}
        select={{
          ...services,
          isMulti: true,
          name: "service_id[]",
          onChange: handleService,
          closeMenuOnSelect: false,
        }}
      />
    </>
  );
};

export default SegmentPlace;

/** @format */

import { Modal, Button, Row } from "react-bootstrap";
import { useState } from "react";
import InputFileLabel from "../../molecules/inputs/input-file-label";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import styled from "styled-components";
const ButtonBox = styled.div`
  margin: 0 auto;
`;

const BaseModal = (props) => {
  const form = props.form;
  const { openModal = false, setOpenModal } = props.modal;
  const { update, create } = props.actions;
  return (
    <Modal size='lg' show={openModal} onHide={() => setOpenModal(false)}>
      <Modal.Header closeButton>
        {JSON.stringify(form)}
        <Modal.Title>{form?.name?.value}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={form?.edit ? update : create}
          encType='multipart/form-data'
          className='form-group'>
          {form?.edit ? (
            <input name='id' type='number' hidden value={form?.id?.value} />
          ) : (
            ""
          )}
          {props.children}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setOpenModal(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal;

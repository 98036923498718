/** @format */

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Card, Row, Col, Badge, Button } from "react-bootstrap";

const CardCategories = (props) => {
  const service = props.data;
  const [open, setOpen] = useState(false);

  return (
    <div key={service.id} className='border col-lg-3 mb-2 p-1'>
      <div>
        <div className='row'>
          <div className='col-lg-9'>
            <Card.Title className='text-capitalize'>{service.name}</Card.Title>
          </div>
          <div className='col-lg-3'>
            <Badge variant='secondary'>{service.service_count}</Badge>
          </div>
          <div className='col-lg-12 text-center'>
            <button
              className=' btn-sm btn-primary '
              onClick={() => props.actions.edit(service.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardCategories;

/** @format */

import React from "react";
import styled from "styled-components";
import InputTextTarea from "../../atoms/inputs/input-text-tarea";
import LabelInput from "../../atoms/labels/label-input";
import ValidationInputText from "../../atoms/texts/validation-input-text";
import { MixinMargins } from "../../../globalstyle/mixinstyle/MixinStyle";

const InputLabelStyle = styled(MixinMargins)`
  width: ${(props) => props.width || "auto"};
`;

const InputTextTareaLabel = (props) => {
  return (
    <InputLabelStyle p={props.p} className={props.className}>
      <LabelInput {...props.label} color={props.labelColor} />
      <InputTextTarea {...props.textarea} />
      <ValidationInputText message={props.message} />
    </InputLabelStyle>
  );
};

export default InputTextTareaLabel;

/** @format */

import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { createLocalStorage, removeToken } from "../../../helper/auth-helpers";
import { getFetch } from "../../../helper/fetchApi";
import MenuPanel from "../menus/menu-panel";
import HeadNavPanel from "../navbars/head-nav-panel";
import "./../../../css/style_panel.css";
import { useLocation } from "react-router-dom";
import rutesMenuPanel from "../../../routes/rutes-menu-panel";
const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  @media (max-width: 840px) {
    display: block;
  }
`;

const BasePanel = (props) => {
  const [isLogin, setIsLogin] = useState(true);
  const { pathname } = useLocation();
  useEffect(() => {
    getFetch("auth/verify").then((response) => {
      if (response.success) {
        setIsLogin(true);
        createLocalStorage(response.data, "user");
      } else {
        removeToken();
        setIsLogin(false);
      }
    });
    const permission = rutesMenuPanel.find((rute) => rute.rute === pathname);
    console.log(permission, "permission");
  }, []);

  document.title = props.page || "title";
  useEffect(() => {
    if (!isLogin) {
      return <Redirect to='/entrar' />;
    }
  }, [isLogin]);

  return (
    <Container>
      <MenuPanel />
      <div className='box_contenido '>
        <HeadNavPanel page={props.page} />
        <div className='contendo mb-5 pb-5 m-3 ' id='contenido'>
          {props.children}
        </div>
      </div>
    </Container>
  );
};

export default BasePanel;

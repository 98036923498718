/** @format */

import { postFetch } from "./../../../helper/fetchApi";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const ModalInformationUser = (props) => {
  const form = props.form;
  const { setOpenModal, openModal } = props;
  const handleClose = () => {};

  const update = (e) => {
    e.preventDefault();

    const formulary = new FormData(e.target);
    postFetch(`services/update`, formulary).then((response) =>
      console.log(response),
    );
  };
  const create = (e) => {
    e.preventDefault();
    const formulary = new FormData(e.target);
    postFetch(`services`, formulary).then((response) => console.log(response));
  };

  const information = props.information;
  return (
    <Modal size='lg' show={openModal} onHide={() => setOpenModal(false)}>
      <Modal.Header closeButton>
        {/* {JSON.stringify(form)} */}
        <Modal.Title>{form?.name?.value}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={form?.edit ? update : create}
          encType='multipart/form-data'
          className='form-group'>
          {form?.edit ? (
            <input name='id' type='number' hidden value={form?.id?.value} />
          ) : (
            ""
          )}
          <div className='col-md-12 p-1 bg-warning'>
            {/* <!-- Widget: user widget style 2 --> */}
            <div className='card card-widget widget-user-2 p-2'>
              {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
              <div className='widget-user-header bg-warning p-2'>
                <div className='row'>
                  <div className='widget-user-image col-lg-4'>
                    <Avatar
                      className='img-circle elevation-2'
                      src={
                        information?.avatar ||
                        "http://192.168.0.186:8000/images/avatar/avatar.png"
                      }
                      alt='User Avatar'
                    />
                  </div>
                  <div className='col-lg-6'>
                    <h3>{information?.user}</h3>
                    <h5>Id: # {information?.id}</h5>
                  </div>
                </div>
              </div>
              <div className='card-footer p-0 row'>
                <ul className='nav flex-column col-lg-6'>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Estado:
                      <span
                        className={`float-right badge  text-white ${
                          information?.user_state?.id === 1
                            ? "bg-success"
                            : information?.user_state?.id === 2
                            ? "bg-warning"
                            : "bg-danger"
                        }`}>
                        {information?.user_state?.name || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Publicaciones favoritas
                      <span className='float-right badge bg-primary text-white'>
                        {information?.favorite_count || 0}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      usuario:
                      <span className='float-right badge bg-info text-white'>
                        {information?.user || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Nombre:
                      <span className='float-right badge bg-info text-white'>
                        {information?.name || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Apellidos:
                      <span className='float-right badge bg-info text-white'>
                        {information?.lastname || "No"}
                      </span>
                    </div>
                  </li>
                  {props.children}
                </ul>
                <ul className='flex-column col-lg-6'>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Email:
                      <span className='float-right badge bg-info text-white'>
                        {information?.email || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      Telefono:{" "}
                      <span className='float-right badge bg-info text-white'>
                        {information?.phone || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      whatsapp:
                      <span className='float-right badge bg-success text-white'>
                        {information?.whatsapp || "No"}
                      </span>
                    </div>
                  </li>
                  <li className='nav-item '>
                    <div className='text-primary nav-link'>
                      Registro:
                      <span className='float-right badge bg-info text-white'>
                        {information?.created_at || "No"}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-12 mt-3 text-center'>
            <button className='btn btn-primary' type='submit'>
              {form?.data.edit ? "Actualizar Usuario" : "Crear Servicio "}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='btn btn-secondary' onClick={() => setOpenModal(false)}>
          Cerrar
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInformationUser;

import React from "react";
import FormRegister from "../../organisms/forms/form-register";
import BasePublic from "../../organisms/bases/base-public";

const Register = (props) => {
    return (
        <BasePublic title="Registrarme - akunaipa">
            <FormRegister />
        </BasePublic>
    );
};

export default Register;

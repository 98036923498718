/** @format */

import styled from "styled-components";
import Buttom from "../../atoms/buttons/button";
import { MixinMargins } from "../../../globalstyle/mixinstyle/MixinStyle";

const ContainerButttom = styled(MixinMargins)`
  width: ${(props) => props.width || "100"}%;
  height: ${(props) => props.height || "40"}%;
  text-align: ${(props) => props.align || "left"};
`;

const ButtomPrimary = (props) => {
  return (
    <ContainerButttom width={props.width} align={props.align} pt={3}>
      <Buttom
        onClick={props.onClick}
        text={props.text}
        width={props.bWidth}
        height={props.bHeight}
      />
    </ContainerButttom>
  );
};

export default ButtomPrimary;

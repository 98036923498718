/** @format */

import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Icon = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
`;

const ItemMenuChildrenPublic = (props) => {
  const [open, setOpen] = useState(false);
  const { id, items = [], movil, text, icon = false } = props;
  return (
    <li className='has-children'>
      <Link href='#'>
        {icon ? <FontAwesomeIcon icon={icon} /> : ""} {text}
      </Link>
      {props.movil ? (
        <Icon
          as={FontAwesomeIcon}
          icon={open ? faArrowUp : faArrowDown}
          data-toggle='collapse'
          data-target={`#collapseItem${id}`}
          onClick={() => setOpen(!open)}
        />
      ) : (
        ""
      )}
      <ul
        className={`dropdown arrow-top ${movil ? "collapse" : ""}`}
        id={movil ? `collapseItem${id}` : ""}>
        {items.map(({ to, text }) => (
          <li>
            <Link to={to}>{text}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
};
export default ItemMenuChildrenPublic;

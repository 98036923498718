/** @format */

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Card, Row, Col, Badge, Button } from "react-bootstrap";
import styled from "styled-components";

const Img = styled.img`
  margin: 0 auto;
  display: none;
  width: 50%;
`;
const Title = styled.p`
  font-size: 12px;
`;
const getDate = () => {
  const allDate = new Date();
  const month =
    allDate.getMonth() < 9 ? `0${allDate.getMonth() + 1}` : allDate.getMonth();
  const date = `${allDate.getFullYear()}/${month}/${allDate.getDate()}`;
  return date;
};
const CardUser = (props) => {
  const user = props.data;
  const [open, setOpen] = useState(false);

  return (
    <div key={user.id} className='col-lg-3 mb-2 '>
      <div className='card pt-2 pb-2'>
        <div className='card-title'>
          <div className='row'>
            <div className='col-lg-12'>
              <Title className='text-capitalize'>{user.email}</Title>
            </div>

            <div className='col-lg-12'>
              <Img
                src={
                  user.avatar ||
                  "http://192.168.0.186:8000/images/avatar/avatar.png"
                }
                alt={user.name}
                className='img-thumbnail rounded  mx-auto d-block'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-8'>
            <button
              className='btn-block btn btn-primary'
              onClick={() => props.openModal(user.id)}>
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardUser;

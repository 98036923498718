/** @format */

import React, { useEffect, useState } from "react";
import InputTextLabel from "../../../molecules/inputs/input-text-label";
import { expresiones } from "../../../../helper/expresiones";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import { ContainerInputs } from "../style/styleForm";
import styled from "styled-components";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  @media (max-width: 840px) {
    display: block;
  }
`;
const ContactInformation = ({ publication, title }) => {
  const [form, setForm] = useState();
  const handledChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          message: expresiones.message[e.target.name],
        },
      });
    }
  };

  useEffect(() => {
    if (publication) {
      const { phone, whatsapp, email } = publication;
      setForm({
        phone: {
          value: phone,
        },
        whatsapp: {
          value: whatsapp,
        },
        email: {
          value: email,
        },
      });
    }
  }, [publication]);

  return (
    <>
      <SubtitleForm text='información de contacto' />

      <Container>
        <InputTextLabel
          label={{
            text: "Telefono",
          }}
          input={{
            placeholder: "Telefono",
            onChange: handledChange,
            message: form?.phone?.message,
            name: "phone",
            value: form?.phone?.value,
            type: "number",
          }}
        />
        <InputTextLabel
          label={{
            text: "Whatsapp",
          }}
          input={{
            type: "number",
            placeholder: "328440****",
            onChange: handledChange,
            message: form?.whatsapp?.message,
            name: "whatsapp",
            value: form?.whatsapp?.value,
          }}
        />
        <InputTextLabel
          label={{
            text: "Correo",
          }}
          input={{
            type: "email",
            placeholder: "example@gmail.com",
            onChange: handledChange,
            message: form?.email?.message,
            name: "email",
            value: form?.email?.value,
          }}
        />
      </Container>
    </>
  );
};

export default ContactInformation;

/** @format */

import CardActions from "../../molecules/cards/card-actions";

const GroupCardsActions = (props) => {
  const { collection } = props.data;

  return (
    <div className='row'>
      {collection.map((data) => (
        <CardActions data={data} onClick={props.action} />
      ))}
    </div>
  );
};

export default GroupCardsActions;

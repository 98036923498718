/** @format */

import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
const ItemMenuPublic = (props) => {
  const location = useLocation();
  const patch = location.pathname;
  const { icon = false, text, to } = props;
  return (
    <li className='bg-sucess'>
      <Link to={to}>
        {icon ? <FontAwesomeIcon icon={icon} /> : ""} {text}
      </Link>
    </li>
  );
};

export default ItemMenuPublic;

import React, { useEffect, useState } from "react";
import { getFetch, postFetch } from "../../../helper/fetchApi";
import GroupCardCategories from "../../organisms/cards/group-card-categories";
import BasePanel from "../../organisms/bases/base-panel";
import { Button, Modal } from "react-bootstrap";
import addValueSelect from "../../../helper/addValueSelect";
import InputSelectLabel from "../../molecules/inputs/input-select-label";
import swall from "sweetalert";
const ServicesCategories = (props) => {
    const [services, setServices] = useState({});
    const [servicesSelect, setServicesSelect] = useState({});

    const [categories, setCategories] = useState([]);
    const [categorieEdit, setCategorieEdit] = useState({});
    useEffect(() => {
        getFetch("services?n").then((services) => {
            setServicesSelect(services.data);
            setServices(addValueSelect(services.data));
        });

        getFetch(
            "categories?order_by=ASC&count_services=true&services=true"
        ).then((services) => {
            setCategories(services.data);
        });
    }, []);

    const closeModal = () => {
        setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState(false);

    const changePage = (id) => {
        getFetch(`services?order_by=ASC&paginate=32&page=${id}`).then(
            (services) => {
                if (services.success) {
                    setServices(services.data);
                }
            }
        );
    };

    const edit = (id) => {
        setOpenModal(true);
        const serviceEdit = categories.find((categorie) => categorie.id === id);
        setCategorieEdit(serviceEdit);
        const selected = serviceEdit.service.map((permision) => ({
            label: permision.name,
            value: permision.id,
        }));

        setServices({
            ...services,
            value: [...selected],
        });
    };
    const update = (e) => {
        e.preventDefault();
        const formulary = new FormData(e.target);
        const categorie_id = parseInt(formulary.get("categorie_id"));
        const service_id = formulary.getAll("service_id[]");
        postFetch("servicescategories", formulary).then((response) =>
            response.success
                ? swall("Servicios asignados", { icon: "success" })
                : swall("error al asignar los servicios", { icon: "warning" })
        );

        const newService = servicesSelect.filter((servi) =>
            service_id.find((s) => parseInt(s) === servi.id)
        );

        const newCategories = categories.map((categorie) => {
            if (categorie.id === categorie_id) {
                return {
                    ...categorie,
                    service_count: service_id.length,
                    service: newService,
                };
            }

            return categorie;
        });

        setCategories(newCategories);
    };

    return (
        <BasePanel page="Servicios Categorias">
            <GroupCardCategories data={categories} actions={{ edit: edit }} />

            <Modal size="lg" show={openModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Servicios {categorieEdit?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={update} className="form-group">
                        <input
                            type="number"
                            name="categorie_id"
                            hidden
                            value={categorieEdit?.id}
                        />
                        <InputSelectLabel
                            label={{ text: "Seleccionar servicios a asignar." }}
                            select={{
                                ...services,
                                name: "service_id[]",
                                isMulti: true,
                                onChange: (e) =>
                                    setServices({ ...services, value: e }),
                            }}
                        />
                        <div className="text-center">
                            <butto className="btn btn-primary " type="submit">
                                Guardar Cambios
                            </butto>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </BasePanel>
    );
};
export default ServicesCategories;

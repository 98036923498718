/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputTextLabel from "./../../molecules/inputs/input-text-label";
import LogoHeadForm from "../../molecules/images/logo-header-form";
import { expresiones } from "../../../helper/expresiones";
import ValidationForm from "../../atoms/texts/validation-form";
import { postFetch } from "../../../helper/fetchApi";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoadingForm from "../../molecules/loading/loading-form";
const ContainerForm = styled.form`
  width: 100%;
  padding: 18% 0%;
  > div {
    background: ${(props) => props.theme.form.background};
    padding: 1% 2%;
    border-radius: 10px;
    margin: 0 auto;
    width: 40%;
  }
  @media (max-width: 840px) {
    padding: 40% 0%;
    > div {
      width: 90%;
      padding: 5% 3%;
    }
  }
`;

const MessageValidation = styled.p`
  padding-left: 10px;
`;

const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 840px) {
    display: block;
  }
`;

const FormRegister = (props) => {
  const [form, setForm] = useState({});
  const [register, setRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {});
  const handleInputChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          message: expresiones.message[e.target.name],
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      form.user?.validate &&
      form.email?.validate &&
      form.password?.validate &&
      form.password_confirmation?.validate
    ) {
      setLoading(true);
      const formulary = new FormData(e.target);
      postFetch("auth/register", formulary).then((response) => {
        if (response.success) {
          swal(
            `Registrado correctamente, se te ha enviado un email a ${formulary.get(
              "email",
            )} para activar tu cuenta`,
            { icon: "success" },
          ).then((response) => setRegister(true));
        } else {
          const corr = Object.keys(response.data);
          const val = Object.values(response.data);
          const redu = (acumulador, current, key) => ({
            ...acumulador,
            [current]: {
              value: form[current].value,
              message: val[key][0],
              validate: false,
            },
          });
          const listErrors = corr.reduce(redu, []);
          setForm({
            ...form,
            ...listErrors,
          });
        }
        setLoading(false);
      });
    }
  };

  if (register) {
    return <Redirect to='/entrar' />;
  }

  return (
    <ContainerForm onSubmit={onSubmit}>
      <div>
        <LogoHeadForm
          cWidth={100} //contenedor
          iWidth={20} //imagen
        />

        <ContainerInputs>
          <InputTextLabel
            label={{
              text: "Usuario",
              color: "tertiary",
            }}
            input={{
              value: form.user?.value || "",
              validate: form.user?.validate,
              placeholder: "Usuario123",
              onChange: handleInputChange,
              name: "user",
            }}
            message={form.user?.message}
          />
          <InputTextLabel
            label={{
              text: "Correo",
              color: "tertiary",
            }}
            input={{
              value: form.email?.value || "",
              validate: form.email?.validate,
              placeholder: "Ingresar Correo",
              onChange: handleInputChange,
              name: "email",
            }}
            message={form.email?.message}
          />
          <InputTextLabel
            label={{
              text: "Contraseña",
              color: "tertiary",
            }}
            input={{
              type: "password",
              value: form.password?.value || "",
              validate: form.password?.validate,
              placeholder: "*******",
              onChange: handleInputChange,
              name: "password",
            }}
            message={form.password?.message}
          />{" "}
          <InputTextLabel
            label={{
              text: "Confirmar Contraseña",
              color: "tertiary",
            }}
            input={{
              type: "password",
              value: form.password_confirmation?.value || "",
              validate: form.password_confirmation?.validate,
              placeholder: "******",
              onChange: handleInputChange,
              name: "password_confirmation",
            }}
            message={form.password_confirmation?.message}
          />
        </ContainerInputs>
        <MessageValidation
          as={ValidationForm}
          message={form.validateForm?.message}
          color={form.validateForm?.color}
        />
        <LoadingForm loading={loading} />
        <button type='submit' className='btn btn-block btn-primary'>
          Registrarme
        </button>
        <div className='mt-2 text-center text-white'>
          ¿Tienes una cuenta?.
          <Link to='/entrar'> Ir a inicio de sesión</Link>
        </div>
        <div className='mt-2 text-center text-white'>
          <Link to='/recover-account'> Olvidaste tu contraseña?</Link>
        </div>
      </div>
    </ContainerForm>
  );
};

export default FormRegister;

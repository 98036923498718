/** @format */

import { faHeart, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ContainerIMage = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 7px;
  -webkit-box-shadow: -3px 7px 29px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -3px 7px 29px -17px rgba(0, 0, 0, 0.75);
  box-shadow: -3px 7px 29px -17px rgba(0, 0, 0, 0.75);
  input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 5px;
    margin-top: 5px;
  }
  .box-trash {
    background: ${(props) => props.theme.colors.primaryError};
    border-radius: 50%;
    color: ${(props) => props.theme.colors.tertiary};
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10px;
    right: 1%;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    z-index: 1;
    display: ${(props) => (props.visible ? "block" : "none")};
  }
`;

const InputFileImage = (props) => {
  return (
    <ContainerIMage visible={props.visible}>
      <input
        type='file'
        data-order={`preview_${props.order}`}
        className={`file_${props.order}`}
        onChange={props.onChange}
        name={props.name}
        accept='image/*'
      />
      <div
        class='box-trash'
        onClick={() => props.onDelete(props.idImage, props.idKey)}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
      <img
        className={`preview_${props.order}`}
        data-preview={`preview_${props.order}`}
        src={props.image || "/images/galeria.jpg"}
        alt='create file'
      />
    </ContainerIMage>
  );
};

export default InputFileImage;

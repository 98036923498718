/** @format */

import FrecuentQuestions from "../components/templates/public/frequent-questions";
import ResultSearch from "../components/templates/public/result-search";
import StartPublic from "../components/templates/public/star-public";
import ViewPublication from "../components/templates/public/view-publication";
import We from "../components/templates/public/we";
import Contact from "../components/templates/public/contact";
import Login from "../components/templates/public/login";
import Register from "../components/templates/public/register";
import RecoverPassword from "../components/templates/public/recover-password";
import ChangePassword from "../components/templates/public/change-password";
import PublicationsCategorie from "../components/templates/public/publications-categorie";
import { getItemStorage, getItemStorageSpeed } from "../helper/local-storage";
let categories = [];
const categorie = getItemStorageSpeed("categories");

if (categorie) {
  categories = categorie.list.map((category) => ({
    path: `/${category.slug}`,
    component: PublicationsCategorie,
  }));
}

const rutesPublic = [
  ...categories,
  {
    path: `/`,
    component: StartPublic,
  },
  {
    path: `/recover-password`,
    component: ChangePassword,
  },
  {
    path: `/nosotros`,
    component: We,
  },
  {
    path: `/recover-account`,
    component: RecoverPassword,
  },
  {
    path: `/entrar`,
    component: Login,
  },
  {
    path: `/registrarme`,
    component: Register,
  },
  {
    path: `/preguntas-frecuentes`,
    component: FrecuentQuestions,
  },
  {
    path: `/contacto`,
    component: Contact,
  },
  {
    path: `/s/:filter/:categorie`,
    component: ResultSearch,
  },
  {
    path: `/hoteles/:categorie/:ubication/:name/:id`,
    component: ViewPublication,
  },
  {
    path: `/experiencias/:categorie/:ubication/:name/:id`,
    component: ViewPublication,
  },
  {
    path: `/guias-locales/:categorie/:ubication/:name/:id`,
    component: ViewPublication,
  },
  {
    path: `/restaurantes/:categorie/:ubication/:name/:id`,
    component: ViewPublication,
  },
];

export default rutesPublic;

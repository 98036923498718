/** @format */

// import React from 'react';

const ThemeStyle = {
  colors: {
    primary: "#FF5C00",
    secondary: "#112236",
    tertiary: "#fbfbfb",
    primaryError: "#ff4040",
    primarySuccess: "#7CBD1E",
    primaryEdit: "#ff7c3d",
    DisablePrimary: "#f39a69",
  },
  form: {
    background: "rgba(0, 0, 0, 0.8)",
  },
  shadow: {
    s124:
      " -4px -4px 12px rgba(0, 0, 0, 0.25), 4px 4px 12px rgba(0, 0, 0, 0.3)",
    card:
      " -4px -4px 12px rgba(0, 0, 0, 0.15), 4px 4px 12px rgba(0, 0, 0, 0.15)",
    table:
      " -1px -1px 5px rgba(0, 0, 0, 0.15), 1px 1px 5px rgba(0, 0, 0, 0.15)",
    tr: "0px 12px 3px -9px rgba(0, 0, 0, 0.15)",
  },
};

export { ThemeStyle };

/** @format */

import styled from "styled-components";

const ContainerForm = styled.form`
  box-shadow: ${(props) => props.theme.shadow.s124};
  padding: 5%;
`;

const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  &:first-letter {
    text-transform: uppercase;
  }
`;

const ContainerTextarea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;

export { ContainerForm, ContainerInputs, ContainerTextarea };

/** @format */

const getDate = () => {
  const fullDate = new Date();
  const day =
    fullDate.getDay() < 9 ? `0` + fullDate.getDay() : fullDate.getDay();
  const month =
    fullDate.getMonth() < 9
      ? `0` + (fullDate.getMonth() + 1)
      : fullDate.getMonth();
  const year = fullDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const getTime = () => {
  const fullDate = new Date();
  const hour =
    fullDate.getHours() < 9 ? `0` + fullDate.getHours() : fullDate.getHours();
  const minutes =
    fullDate.getMinutes() < 9
      ? `0` + (fullDate.getMinutes() + 1)
      : fullDate.getMinutes();
  const seconst = fullDate.getSeconds();
  return `${hour}:${minutes}:${seconst}`;
};
const differenceDates = (date = "2000/02/15 06:59:00") => {
  var date1 = new Date(date);
  var date2 = new Date(); //Customise date2 for your required future timevar
  var diff = (date2 - date1) / 1000;
  var diff = Math.abs(Math.floor(diff));
  var years = Math.floor(diff / (365 * 24 * 60 * 60));
  var leftSec = diff - years * 365 * 24 * 60 * 60;
  var month = Math.floor(leftSec / ((365 / 12) * 24 * 60 * 60));
  var leftSec = leftSec - month * (365 / 12) * 24 * 60 * 60;
  var days = Math.floor(leftSec / (24 * 60 * 60));
  var leftSec = leftSec - days * 24 * 60 * 60;
  var hrs = Math.floor(leftSec / (60 * 60));
  var leftSec = leftSec - hrs * 60 * 60;
  var min = Math.floor(leftSec / 60);
  var leftSec = leftSec - min * 60;

  return {
    years: years,
    month: month,
    days: days,
    hours: hrs,
    minutes: min,
    seconds: leftSec,
  };
};
export { getDate, getTime, differenceDates };

import React, { useEffect, useState } from "react";
import BaseProfile from "../../organisms/bases/base-profile";
import styled from "styled-components";
import ButtonAddAction from "../../atoms/buttons/button-add-action";
import ModalCreateService from "../../organisms/modals/modal-create-service";
import GroupCardServices from "../../organisms/cards/group-card-services";
import { getFetch, deleteFetch } from "../../../helper/fetchApi";
import BasePanel from "../../organisms/bases/base-panel";
import swal from "sweetalert";
const BoxCreatedAdd = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
`;

const ManageService = (props) => {
    const [form, setForm] = useState({});
    const [services, setServices] = useState({});
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        getFetch("services?order_by=ASC&paginate=32").then((services) =>
            services.success ? setServices(services.data) : null
        );
    }, []);

    const changePage = (id) => {
        getFetch(`services?order_by=ASC&paginate=32&page=${id}`).then(
            (services) => {
                if (services.success) {
                    console.log(services.data);
                    setServices(services.data);
                }
            }
        );
    };

    const search = (e) => {
        getFetch(
            `services?order_by=ASC&paginate=32&search=${e.target.value}`
        ).then((services) => {
            setServices(services.data);
        });
    };

    const edit = (id) => {
        setOpenModal(true);
        const serviceEdit = services.data.find((service) => service.id === id);
        const llaves = Object.keys(serviceEdit);
        const l = {};
        llaves.map(
            (llave) =>
                (l[llave] = { value: serviceEdit[llave], validate: true })
        );

        setForm({
            edit: true,
            ...l,
        });
    };
    const openModalService = () => {
        setOpenModal(true);
        setForm({ edit: false });
    };
    const remove = (id) => {
        swal({
            title: "Desea eliminar el servicio?",
            text:
                "El servicio sera eliminado del las publicaciones que lo tengan añadidos en su lista, despues de eliminado no podra recuperarse!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteFetch(`services/${id}`).then((response) =>
                    response.success
                        ? swal("servicio eliminado correctamente", {
                              icon: "success",
                          })
                        : null
                );
                // swal("Poof! Your imaginary file has been deleted!", {
                //     icon: "success",
                // });
            } else {
                swal("Your imaginary file is safe!");
            }
        });
    };
    return (
        <BasePanel page="Gestionar Servicios">
            <ModalCreateService
                modal={{ openModal: openModal, setOpenModal: setOpenModal }}
                form={form}
                setForm={setForm}
            />
            <BoxCreatedAdd>
                <div></div>
                <ButtonAddAction
                    onClick={() => openModalService()}
                    text="Crear servicio"
                />
            </BoxCreatedAdd>
            <input type="search" placeholder="Buscar" onChange={search} />
            <GroupCardServices
                services={services}
                change={changePage}
                actions={{ edit: edit, delete: remove }}
            />
        </BasePanel>
    );
};

export default ManageService;

/** @format */

const formatCurrency = (number) => {
  var formatted = new Intl.NumberFormat("CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  }).format(number);
  return formatted;
};

export default formatCurrency;

/** @format */

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ButtomStyle = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.tertiary};
  cursor: pointer;
  width: ${(props) => props.width || "100"}%;
  height: ${(props) => props.height || "40"}px;
  font-weight: bold;
  ${(props) =>
    props.auto &&
    css`
      margin: 0 auto;
      display: block;
    `}
`;

const Buttom = (props) => {
  return (
    <ButtomStyle
      type='submit'
      height={props.height}
      width={props.width}
      auto={props.auto}>
      {props.text}
    </ButtomStyle>
  );
};

Buttom.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Buttom;

/** @format */

import React from "react";
import styled from "styled-components";
import InputTime from "../../atoms/inputs/input-time";
import LabelInput from "../../atoms/labels/label-input";
import ValidationInputText from "../../atoms/texts/validation-input-text";
import { MixinMargins } from "../../../globalstyle/mixinstyle/MixinStyle";

const InputTimeLabel = (props) => {
  const { className, input, label, message } = props;

  return (
    <div className={className}>
      <LabelInput {...label} />
      <InputTime {...input} />
      <ValidationInputText {...message} />
    </div>
  );
};

export default InputTimeLabel;

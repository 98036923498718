/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getFetch } from "../../../helper/fetchApi";
import { Pagination } from "react-bootstrap";
import CardPublication from "../../molecules/cards/card-publication";

const GroupCardsFavoriteStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
`;

const GroupCardsFavorite = (props) => {
  const [cards, setCards] = useState({ data: [] });
  const url =
    "favorites?&municipalitie-department=true&price_min=true&payments_count=true&categorie-parent_categorie=true&favorites_count=true&publication_state=name&imagespublication=true&paginate=12";
  useEffect(() => {
    getFetch(url).then((response) =>
      response.success ? setCards(response.data) : "",
    );
  }, []);
  const cargar = () => {
    getFetch(url).then((response) =>
      response.success ? setCards(response.data) : "",
    );
  };

  const changePage = (id) => {
    getFetch(`${url}&page=${id}`).then((response) =>
      response.success ? setCards(response.data) : "",
    );
  };

  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    const newPagination = [];
    if (cards?.data?.length > 0) {
      if (cards.last_page > 1) {
        for (let number = 1; number <= cards.last_page; number++) {
          newPagination.push(
            <Pagination.Item
              key={number}
              active={number === cards.current_page}
              onClick={() => changePage(number)}>
              {number}
            </Pagination.Item>,
          );
        }
      }
    }
    setPagination(newPagination);
  }, [cards]);

  return (
    <>
      <button onClick={() => cargar()}>Cargar</button>
      {/* {JSON.stringify(cards)} */}
      <div className='row'>
        {cards.data.map((card) => (
          <CardPublication publication={card} className='col-lg-4' />
        ))}
      </div>
      <GroupCardsFavoriteStyle>
        <div className='col-lg-12  mt-3'>
          <Pagination>{pagination}</Pagination>
        </div>
        {/* {cards.data.map((dat) => (
        <CardFavorite publication={dat} />
      ))} */}
      </GroupCardsFavoriteStyle>
    </>
  );
};

export default GroupCardsFavorite;

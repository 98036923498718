/** @format */

import {
  faCaretDown,
  faHandHoldingUsd,
  faHeart,
  faHome,
  faList,
  faPlusCircle,
  faSignOutAlt,
  faTools,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import PublicationsActives from "../components/templates/panel/publications-actives.jsx";
import PublicationsEdit from "../components/templates/panel/publications-edit.jsx";
import PublicationsDenied from "../components/templates/panel/publications-denied";
import { URL_PANEL } from "./../const/const.js";
import PublicationsReview from "../components/templates/panel/publications-review.jsx";
import PreviewPublication from "../components/templates/public/preview-publication.jsx";
import ManageService from "../components/templates/panel/manage-service.jsx";
import ListUsers from "../components/templates/panel/list-users.jsx";
import ListClients from "../components/templates/panel/list-clients.jsx";
import EditProfile from "../components/templates/panel/edit-profile.jsx";
import ListModerators from "../components/templates/panel/list-moderators.jsx";
import CreatePublication from "../components/templates/panel/create-publication.jsx";
import ServicesCategories from "../components/templates/panel/services-categories.jsx";
import MyFavorites from "../components/templates/panel/my-favorites.jsx";
import RolesAndPermissions from "../components/templates/panel/roles-and-permisions.jsx";
import EditPublication from "../components/templates/panel/edit-publication.jsx";
import CloseSession from "../components/templates/panel/close-session.jsx";

const rutesMenuPanel = [
  {
    icon: faHome,
    text: "inicio",
    rute: `${URL_PANEL}`,
    component: EditProfile,
  },
  {
    rute: `${URL_PANEL}publication/edit/:id`,
    component: EditPublication,
  },
  {
    icon: faSignOutAlt,
    text: "Cerrar sesión",
    rute: `${URL_PANEL}cerrar-sesion`,
    component: CloseSession,
  },
  {
    icon: faHome,
    text: "inicio",
    rute: `${URL_PANEL}`,
    component: PreviewPublication,
  },
  {
    id: 1,
    icon: faPlusCircle,
    text: "Crear publicación",
    rute: `${URL_PANEL}crear-publicacion`,
    component: CreatePublication,
  },
  {
    id: 65,
    icon: faList,
    text: "Mis publicaciones",
    rute: `${URL_PANEL}mis-publicaciones`,
  },
  {
    id: 31,
    icon: faPlusCircle,
    text: "Servicios categorias",
    rute: `${URL_PANEL}gestionar-servicios-categorias`,
    component: ServicesCategories,
  },
  {
    id: 39,
    icon: faHeart,
    text: "Mis favoritos",
    rute: `${URL_PANEL}mis-favoritos`,
    component: MyFavorites,
  },
  {
    id: 43,
    icon: faUser,
    text: "Usuarios",
    rute: `${URL_PANEL}usuarios`,
    component: ListUsers,
  },
  {
    id: 44,
    icon: faUserPlus,
    text: "Clientes",
    rute: `${URL_PANEL}clientes`,
    component: ListClients,
  },
  {
    id: 45,
    icon: faUserPlus,
    text: "moderadores",
    rute: `${URL_PANEL}moderadores`,
    component: ListModerators,
  },
  {
    id: 46,
    icon: faUserPlus,
    text: "Administradores",
    rute: `${URL_PANEL}administradores`,
    component: ListUsers,
  },
  {
    id: 47,
    icon: faUserPlus,
    text: "contadores",
    rute: `${URL_PANEL}contadores`,
  },
  {
    id: 48,
    icon: faUserPlus,
    text: "financieros",
    rute: `${URL_PANEL}financieros`,
  },

  {
    id: 70,
    icon: faList,
    text: "roles y permisos",
    rute: `${URL_PANEL}roles-y-permisos`,
  },
  {
    id: 7,
    icon: faList,
    text: "Publicaciones Revision",
    rute: `${URL_PANEL}publicaciones/revision`,
    component: PublicationsReview,
  },
  {
    id: 6,
    icon: faList,
    text: "Publicaciones negadas",
    rute: `${URL_PANEL}publicaciones-/negadas`,
    component: PublicationsDenied,
  },
  {
    id: 5,
    icon: faList,
    text: "Publicaciones Edicion",
    rute: `${URL_PANEL}publicaciones/edicion`,
    component: PublicationsEdit,
  },
  {
    id: 4,
    icon: faList,
    text: "Publicaciones Activas",
    rute: `${URL_PANEL}publicaciones/activas`,
    component: PublicationsActives,
  },
  {
    id: 8,
    icon: faCaretDown,
    text: "Publicaciones VIP",
    rute: `${URL_PANEL}publicaciones/edicion`,
  },
  {
    id: 49,
    icon: faTools,
    text: "Editar perfil",
    rute: `${URL_PANEL}editar-perfil`,
    component: EditProfile,
  },
  {
    id: 74,
    icon: faHandHoldingUsd,
    text: "Ver pagos",
    rute: `${URL_PANEL}ver-pagos`,
  },

  {
    id: 731,
    icon: faSignOutAlt,
    text: "Cerrar sessión",
    rute: `${URL_PANEL}cerrar-session`,
  },
  {
    id: 28,
    icon: faSignOutAlt,
    text: "Manejo servicios",
    rute: `${URL_PANEL}menejo-servicios`,
    component: ManageService,
  },
  {
    id: 72,
    rute: `${URL_PANEL}permissions-roles`,
    text: "permisos y roles",
    icon: faSignOutAlt,
    component: RolesAndPermissions,
  },
];

export default rutesMenuPanel;

/** @format */

import styled from "styled-components";

const Label = styled.label`
  color: ${(props) =>
    props.theme.colors[props.color] || props.theme.colors.secondary};
  width: 100%;
`;

const LabelInput = (props) => {
  return <Label color={props.color}>{props.text}</Label>;
};

export default LabelInput;

/** @format */

import { Pagination, Row } from "react-bootstrap";
import CardService from "../../molecules/cards/card-service";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CardPublicationPanel from "../../molecules/cards/card-publication-panel";
const BoxPaginate = styled.div`
  display: flex;
  align-items: center;
  ul {
    margin: 0 auto;
  }
`;
const GroupCardPublicationPanel = (props) => {
  const publications = props.data.data || [];
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    const newPagination = [];

    for (let number = 1; number <= props.data.last_page; number++) {
      newPagination.push(
        <Pagination.Item
          key={number}
          active={number === props.data.current_page}
          onClick={() => props.change(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    setPagination(newPagination);
  }, [props.data]);
  return (
    <Row className='mt-3'>
      {publications.map((publication) => (
        <CardPublicationPanel
          actions={{ ...props.actions }}
          data={publication}
        />
      ))}
      <BoxPaginate className='col-lg-12  mt-3'>
        <Pagination>{pagination}</Pagination>
      </BoxPaginate>
    </Row>
  );
};
export default GroupCardPublicationPanel;

/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { URL_API } from "../../const/const";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const LinkExportExcel = (props) => {
  return (
    <div className='form-group col-lg-3'>
      <label>Exportar</label>
      <div>
        <a
          href={`${URL_API}${props.url || ""}`}
          className='btn btn-success btn-block'>
          <FontAwesomeIcon icon={faFileExcel} />
        </a>
      </div>
    </div>
  );
};

export default LinkExportExcel;

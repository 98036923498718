import React, { Suspense } from "react";
import BasePanel from "../../organisms/bases/base-panel";
import FormEditProfile from "../../organisms/forms/form-edit-profile";

const EditProfile = (props) => {
    return (
        <div>
            <BasePanel page="Editar perfil">
                <Suspense fallback={<div>Loading...</div>}>
                    <FormEditProfile />
                </Suspense>
            </BasePanel>
        </div>
    );
};

export default EditProfile;

/** @format */

const addValueSelect = (colections, label = "name", value = "id") => {
  let finalColection = "";
  if (Object.keys(colections).length !== 0) {
    const newColection = colections.map((colection) => ({
      label: colection[label],
      value: colection[value],
    }));

    finalColection = {
      options: newColection,
      value: [
        {
          label: newColection[0].label,
          value: newColection[0].value,
        },
      ],
    };
  } else {
    finalColection = {
      options: [{ value: "", label: "Sin Opciones" }],
      value: [
        {
          label: "Sin Opciones",
          value: "",
        },
      ],
    };
  }

  return finalColection;
};

export default addValueSelect;

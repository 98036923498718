import BasePublic from "../../organisms/bases/base-public";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFetch } from "../../../helper/fetchApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import formatCurrency from "../../../helper/format-currency";
import SubtitleCategorie from "../../atoms/texts/subtitle-categorie";
import GroupCardsPublication from "../../organisms/cards/group-card-publication";
import { Accordion } from "react-bootstrap";

import ImageGallery from "react-image-gallery";
import WhatsappFloat from "../../organisms/whatsapp/whatsapp-float";
const PreviewPublication = (props) => {
    let { id } = useParams();
    const [gallery, setGallery] = useState();
    const [
        {
            continuos_service,
            closed,
            title,
            favorites_count,
            indication,
            description,
            imagespublication,
            municipalitie,
            open,
            price_min,
            prices,
        },
        setPublication,
    ] = useState({});
    useEffect(() => {
        getFetch(
            `publications/preview/${id}?imagespublication=true&favorites_count=true&municipalitie-department=name&price_min=true&prices=true`
        ).then((response) => {
            if (response.success) {
                console.log(response.data);
                setPublication(response.data);
            }
        });
    }, []);

    const reload = () => {
        getFetch(
            `publications/preview/${id}?imagespublication=true&favorites_count=true&municipalitie-department=name&price_min=true&prices=true`
        ).then((response) => {
            if (response.success) {
                setPublication(response.data);
            }
        });
    };

    useEffect(() => {
        if (imagespublication !== undefined && imagespublication !== null) {
            const n = imagespublication.map((res) => {
                return {
                    original: res.url,
                    thumbnail: res.url,
                };
            });

            setGallery(
                <ImageGallery showNav={false} lazyLoad={true} items={n} />
            );
        }
    }, [imagespublication]);

    const horary = (hourComplete, am = false) => {
        if (hourComplete) {
            const hour = hourComplete.split(":");
            // console.log(n);
            if (hour[0] < 12) {
                return `${hour[0]}:${hour[0]} am`;
            } else {
                return `${hour[0]}:${hour[0]} pm`;
            }
        }
    };
    return (
        <BasePublic title={title} carousel formsearch>
            <div class="mt-3" id="detalles">
                <div class="row">
                    <div class="col-lg-6">
                        {gallery}
                        {/* <CarouselDetails images={imagespublication} type={0} /> */}
                    </div>
                    {/* {JSON.stringify(imagespublication)} */}

                    <div class="col-lg-6 mt-1">
                        <h3 class="nom_pu_info text-success mt-3 mb-3">
                            {title}
                        </h3>

                        <span class="property-location d-block mb-2 mt-2 text-capitalize">
                            <FontAwesomeIcon icon={faMapMarkedAlt} />{" "}
                            {municipalitie?.name} -{" "}
                            {municipalitie?.department?.name}
                        </span>

                        <strong class="property-price text-warning mb-3 d-block ">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />({favorites_count})
                        </strong>
                        <button onClick={() => reload()}>Reload</button>
                        <b>Horario de atención</b>

                        <div class="mt-2 mb-4">
                            {continuos_service
                                ? "24 Horas"
                                : `${horary(open, true)} - ${horary(closed)}`}
                        </div>

                        <strong class="h3 mb-3 text-success mb-2">
                            {price_min ? formatCurrency(price_min?.price) : ""}
                        </strong>

                        <div class="lista_precios table-responsive mt-4"></div>

                        <Accordion defaultActiveKey="0" className="mb-2">
                            <Accordion.Toggle
                                as="div"
                                className="btn btn-primary mt-2"
                                variant="link"
                                eventKey="1"
                            >
                                ver mas precios
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <table class="table table-sm mt-2">
                                    <thead class="table-info">
                                        <tr>
                                            <th scope="col">Precios</th>

                                            <th scope="col">Caracteristica</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {prices !== undefined
                                            ? prices.map((price) => (
                                                  <tr>
                                                      <th scope="col">
                                                          {formatCurrency(
                                                              price.price
                                                          )}
                                                      </th>

                                                      <th scope="col">
                                                          {price.name}
                                                      </th>
                                                  </tr>
                                              ))
                                            : ""}
                                    </tbody>
                                </table>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                </div>
                <div class="mt-3">
                    <h4 class="h4 text-black col-lg-12">Indicaciones</h4>

                    <p class="col-lg-12">{indication}</p>

                    <h4 class="h4 text-black col-lg-12 ">Descripcion</h4>

                    <p class="col-lg-12">{description}</p>
                </div>{" "}
            </div>
            <WhatsappFloat />

            <SubtitleCategorie text="Hoteles" />
            <GroupCardsPublication idCategorie={1} />
            <SubtitleCategorie text="Restaurantes" />
            <GroupCardsPublication idCategorie={2} />
            <SubtitleCategorie text="Experiencias" />
            <GroupCardsPublication idCategorie={3} />
            <SubtitleCategorie text="Guias Locales" />
            <GroupCardsPublication idCategorie={4} />
        </BasePublic>
    );
};

export default PreviewPublication;

/** @format */

import React, { useState, useEffect } from "react";
import InputSelectLabel from "../../../molecules/inputs/input-select-label";
import InputTextLabel from "../../../molecules/inputs/input-text-label";
import { expresiones } from "../../../../helper/expresiones";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import {
  initPais,
  initSelect,
} from "../stateinitialforms/init-form-create-publication";
import { ContainerInputs } from "../style/styleForm";

import { getFetch } from "../../../../helper/fetchApi";
import addValueSelect from "../../../../helper/addValueSelect";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  @media (max-width: 840px) {
    display: block;
  }
`;

const InformationUbication = ({ create, publication = {}, title }) => {
  const { municipalitie, direction } = publication;
  const [form, setForm] = useState();
  const [departments, setDepartments] = useState({});
  const [municipalities, setMunicipalities] = useState(initSelect);
  useEffect(() => {
    if (Object.entries(publication).length !== 0) {
      setForm({
        direction: {
          value: direction,
        },
      });
      getFetch("departments?order_by=ASC")
        .then((response) => {
          const newDepartments = addValueSelect(response.data);
          setDepartments({
            ...newDepartments,
            value: {
              label: municipalitie?.department?.name,
              value: municipalitie?.department?.id,
            },
          });
          return municipalitie?.department?.id;
        })
        .then((department_id) => {
          getFetch(
            `departments/${department_id}/municipalities?order_by=ASC`,
          ).then((municipalities) => {
            const newMunicipalities = addValueSelect(municipalities.data);

            setMunicipalities({
              ...newMunicipalities,
              value: {
                label: municipalitie.name,
                value: municipalitie.id,
              },
            });
          });
        });
    }
  }, [publication]);

  useEffect(() => {
    if (create) {
      getFetch("departments?order_by=ASC")
        .then((response) => {
          const newDepartments = addValueSelect(response.data);
          setDepartments(newDepartments);
          return newDepartments.options[0].value;
        })
        .then((departmentId) => {
          getFetch(
            `departments/${departmentId}/municipalities?order_by=ASC`,
          ).then((municipalities) => {
            const newMunicipalities = addValueSelect(municipalities.data);

            setMunicipalities(newMunicipalities);
          });
        });
    }
  }, []);

  const handledChange = (e) => {
    expresiones.validate[e.target.name].test(e.target.value)
      ? setForm({
          ...form,
          [e.target.name]: {
            value: e.target.value,
            validate: true,
          },
        })
      : setForm({
          ...form.data,
          [e.target.name]: {
            value: e.target.value,
            message: expresiones.message[e.target.name],
          },
        });
  };

  const handledChangeDepartment = (ev, meta) => {
    setDepartments({
      ...departments,
      value: { ...ev },
    });

    getFetch(`departments/${ev.value}/municipalities`).then(
      (municipalities) => {
        const newMunicipalities = addValueSelect(municipalities.data);
        setMunicipalities(newMunicipalities);
      },
    );
  };

  const handledChangeMunicipalities = (ev, meta) => {
    setMunicipalities({
      ...municipalities,
      value: { ...ev },
    });
  };

  return (
    <>
      <SubtitleForm text={title} />
      <Container>
        <InputSelectLabel
          label={{
            text: "Pais",
          }}
          select={{
            name: "pais",
            value: initPais,
            options: initPais,
          }}
        />
        <InputSelectLabel
          label={{
            text: "Departamento",
          }}
          select={{
            ...departments,
            onChange: handledChangeDepartment,
            name: "department_id",
            isSearchable: true,
          }}
        />

        <InputSelectLabel
          label={{
            text: "Municipio",
          }}
          select={{
            ...municipalities,
            onChange: handledChangeMunicipalities,
            name: "municipalitie_id",
          }}
        />
        <InputTextLabel
          label={{
            text: "Barrio",
          }}
          input={{
            placeholder: "Barrio",
            onChange: handledChange,
            name: "neighborhood",
            value: form?.neighborhood?.value,
          }}
        />
        <InputTextLabel
          label={{
            text: "Dirección",
          }}
          input={{
            placeholder: "Direccion",
            onChange: handledChange,
            name: "direction",
            value: form?.direction?.value,
            message: form?.direction?.message,
          }}
        />
      </Container>
    </>
  );
};

export default InformationUbication;

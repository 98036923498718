/** @format */

import swal from "sweetalert";
import { URL_API } from "../const/const";
import { getDataUser, headerAutorization } from "./auth-helpers";

const getFetch = async (uri) => {
  const header = headerAutorization("GET");
  const response = await fetch(`${URL_API}${uri}`, header);
  switch (response.status) {
    case 404:
      swal("ruta no encontrar", "", "error");
      break;
    case 403:
      swal("No tienes los permisos suficientes.", "", "error");
      break;
    case 401:
      swal("No autorizado", "", "error");
      break;
    case 500:
      swal("Error interno", "", "error");
      break;

    default:
      break;
  }

  return response.ok ? await response.json() : response.status;
};

const getVerify = async (uri) => {
  const header = headerAutorization("GET");
  const response = await fetch(`${URL_API}${uri}`, header);
  return response.json();
};

const postFetch = async (uri, datos) => {
  const header = headerAutorization("POST", datos);
  const response = await fetch(`${URL_API}${uri}`, header);

  switch (response.status) {
    case 404:
      swal("ruta no encontrar", "", "error");
      break;
    case 403:
      swal("faltan permisos", "", "error");
      break;
    case 401:
      swal("No autorizado", "", "error");
      break;
    case 500:
      swal("Error interno", "", "error");
      break;
    case 422:
      break;

    default:
      break;
  }
  return await response.json();
};

const deleteFetch = async (uri) => {
  const header = headerAutorization("DELETE");
  const response = await fetch(`${URL_API}${uri}`, header);
  let data;
  switch (response.status) {
    case 404:
      swal("ruta no encontrar", "", "error");
      data = false;
      break;
    case 403:
      swal("faltan permisos", "", "error");
      data = false;
      break;
    case 401:
      swal("No autorizado", "", "error");
      data = false;
      break;
    case 500:
      swal("Error interno", "", "error");
      data = false;
      break;

    default:
      data = await response.json();
      break;
  }
  return data;
};

const putFetch = async (uri, datos) => {
  const { token } = getDataUser();

  const header = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    body: datos,
  };
  const response = await fetch(`${URL_API}${uri}/update`, header);
  let data;
  switch (response.status) {
    case 404:
      swal("ruta no encontrar", "", "error");
      data = false;
      break;
    case 403:
      swal("faltan permisos", "", "error");
      data = false;
      break;
    case 401:
      swal("No autorizado", "", "error");
      data = false;
      break;
    case 500:
      swal("Error interno", "", "error");
      data = false;
      break;

    default:
      data = await response.json();
      break;
  }
  return data;
};

export { postFetch, getFetch, deleteFetch, putFetch, getVerify };

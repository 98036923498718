/** @format */

import React from "react";
import styled from "styled-components";

const ContainerInput = styled.div`
  position: relative;
  width: ${(props) => props.width || "100"}%;
  input {
    border: 0px;
    height: ${(props) => (props.height ? props.height : 32)}px;
    width: 100%;
    padding-left: 10px;
    border-radius: 5px;
    margin-top: 5px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }
`;

const InputTime = (props) => {
  const { disabled, min, max, placeholder, onChange, name, value } = props;
  return (
    <ContainerInput>
      <input
        type='time'
        min={min || "00:00"}
        max={max || "12:00"}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
      />
    </ContainerInput>
  );
};

export default InputTime;

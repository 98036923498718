/** @format */

import React from "react";
import styled from "styled-components";

const ContainerInput = styled.div`
  position: relative;
  width: ${(props) => props.width || "100"}%;
`;

const InputTextStyle = styled.input`
  border: 0px;
  height: ${(props) => (props.height ? props.height : 32)}px;
  width: 100%;
  padding-left: 10px;
  border-radius: 5px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const InputText = (props) => {
  return (
    <ContainerInput>
      <InputTextStyle
        type={props.type || "text"}
        placeholder={props.placeholder || ""}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        autoComplete={props.autoComplete ? "on" : "off"}
        className={props?.className}
      />
    </ContainerInput>
  );
};

export default InputText;

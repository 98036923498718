/** @format */

import { Pagination, Row } from "react-bootstrap";
import CardService from "../../molecules/cards/card-service";
import { useEffect, useState } from "react";
import styled from "styled-components";
const BoxPaginate = styled.div`
  display: flex;
  align-items: center;
  ul {
    margin: 0 auto;
  }
`;
const GroupCardService = (props) => {
  const [pagination, setPagination] = useState([]);
  const [listServices, setListServices] = useState([]);

  useEffect(() => {
    if (props.services.data !== undefined) {
      setListServices(props.services.data);
      const newPagination = [];
      for (let number = 1; number <= props.services.last_page; number++) {
        newPagination.push(
          <Pagination.Item
            key={number}
            active={number === props.services.current_page}
            onClick={() => props.change(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      setPagination(newPagination);
    }
  }, [props.services]);

  return (
    <Row className='mt-3'>
      {listServices.map((service) => (
        <CardService data={service} actions={{ ...props.actions }} />
      ))}
      <BoxPaginate className='col-lg-12  mt-3'>
        <Pagination>{pagination}</Pagination>
      </BoxPaginate>
    </Row>
  );
};
export default GroupCardService;

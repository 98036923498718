/** @format */

import {
  faHome,
  faList,
  faQuestionCircle,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import styled from "styled-components";
import ItemMenuChildrenPublic from "./item-menu-children-public";
import ItemMenuPublic from "./item-menu-public";

const IconClose = styled.div`
  width: 100%;
  float: left;
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
  div {
    font-size: 30px;
  }
`;

const MenuMovilPublic = (props) => {
  const { login = false } = props;
  useEffect(() => {
    props.movil.open
      ? document.body.classList.add("offcanvas-menu")
      : document.body.classList.remove("offcanvas-menu");
  }, [props.movil.open]);
  return (
    <div className='site-mobile-menu'>
      <IconClose>
        <div
          className='site-mobile-menu-close '
          onClick={() => props.movil.set(!props.movil.open)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </IconClose>

      <div className='site-mobile-menu-body'>
        <ul className='site-nav-wrap'>
          <ItemMenuPublic icon={faHome} text='Inicio' to='/' />
          <ItemMenuChildrenPublic
            icon={faQuestionCircle}
            id={0}
            items={[
              { to: "nosotros", text: "Nosotros" },
              { to: "preguntas-frecuentes", text: "Como publicar" },
              { to: "contacto", text: "Contacto" },
            ]}
            movil
            text='Preguntas frecuentes'
          />
          <ItemMenuChildrenPublic
            icon={faList}
            id={1}
            items={[
              { to: "hoteles", text: "Hoteles" },
              { to: "restaurantes", text: "Restaurantes" },
              { to: "expreriencias", text: "Experiancias" },
              { to: "guias-locales", text: "Guias Locales" },
            ]}
            movil
            text='Categorias'
          />

          {login ? (
            <>
              <ItemMenuPublic icon={faUser} text='Panel' to='/panel' />
              <ItemMenuPublic
                icon={faTimes}
                text='Cerrar sessión'
                to='/panel/cerrar-sesion'
              />
            </>
          ) : (
            <>
              <ItemMenuPublic text='Entrar' to='/entrar' />
              <ItemMenuPublic text='Registrarme' to='registrarme' />
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
export default MenuMovilPublic;

/** @format */

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
const Box = styled.div`
  .whatsapp_flotante {
    transition: 10s;

    cursor: pointer;

    width: 55px;

    height: 55px;

    border-radius: 50%;

    text-align: center;

    position: fixed;

    z-index: 999999;

    bottom: 5%;

    right: 5%;

    overflow: hidden;

    color: #fff;

    -webkit-box-shadow: 0px 0px 18px -6px rgba(37, 211, 102, 1);

    -moz-box-shadow: 0px 0px 18px -6px rgba(37, 211, 101, 1);

    box-shadow: 0px 0px 18px -6px rgba(37, 211, 101, 1);

    background-image: linear-gradient(
      0deg,
      rgba(37, 211, 102, 1) 4%,
      rgba(37, 211, 102, 1) 45%,
      rgba(37, 211, 102, 1) 100%
    );
  }

  .header_send_whatsapp {
    margin-bottom: 10px;
  }

  .whatsapp_flotante:hover {
    background-image: linear-gradient(
      0deg,
      #128c7e 4%,
      #128c7e 45%,
      #128c7e 100%
    );
  }

  .whatsapp_flotante svg {
    font-size: 55px;

    padding: 5px;

    line-height: 1.3;
  }

  /*whatsapp*/

  .send_whatsapp {
    display: none;

    background: rgba(37, 211, 102, 1);

    border-radius: 5%;

    padding: 5px;

    width: 20%;

    position: fixed;

    right: 12%;

    z-index: 99999999;

    bottom: 12%;

    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);

    background: rgb(37, 211, 102) !important;
  }

  .send_whatsapp .box_shared {
    width: 100%;

    background: rgba(37, 211, 102, 1);
  }

  .box_shared div {
    text-align: center;
  }

  textarea {
    border-radius: 5px;

    width: 80%;

    margin-bottom: 2%;
  }

  .enviar_mensaje {
    background: #128c7e;

    width: 50%;

    padding: 5px;

    border-radius: 5px;
  }

  /* galerias */

  #myImg {
    border-radius: 5px;

    cursor: pointer;

    transition: 0.3s;
  }

  #myImg:hover {
    opacity: 0.7;
  }

  /* The Modal (background) */

  .modal {
    display: none;

    /* Hidden by default */

    position: fixed;

    /* Stay in place */

    z-index: 1;

    /* Sit on top */

    padding-top: 100px;

    /* Location of the box */

    left: 0;

    top: 0;

    width: 100%;

    /* Full width */

    height: 100vh;

    /* Full height */

    overflow: auto;

    /* Enable scroll if needed */

    /*background-color: rgb(0,0,0); /* Fallback color */

    background-color: rgba(0, 0, 0, 0.5);

    /* Black w/ opacity */
  }

  .modal-content {
    margin: auto;

    display: block;

    width: auto;

    max-width: 700px;

    height: 60vh !important;

    border-radius: 10px;
  }

  /* Caption of Modal Image */

  #caption {
    margin: auto;

    display: block;

    width: 80%;

    max-width: 700px;

    text-align: center;

    color: #ccc;

    padding: 10px 0;

    height: 150px;
  }

  /* Add Animation */

  .modal-content,
  #caption {
    -webkit-animation-name: zoom;

    -webkit-animation-duration: 0.6s;

    animation-name: zoom;

    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }

    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  /* The Close Button */

  .cerrar_modal_img {
    position: absolute;

    top: 15px;

    right: 35px;

    color: #f1f1f1;

    font-size: 40px;

    font-weight: bold;

    transition: 0.3s;
  }

  .cerrar_modal_img:hover,
  .cerrar_modal_img:focus {
    color: #bbb;

    text-decoration: none;

    cursor: pointer;
  }

  .site-section {
    padding: 0em 0;
  }

  /* galeria imagenes */

  .galery_fotos {
    width: auto;
  }

  .galery_fotos > img {
    width: 100%;

    height: 170px !important;

    border-radius: 5px;
  }

  @media (max-width: 840px) {
    .galery_fotos {
      width: 50%;
    }

    .galery_fotos > img {
      width: 100%;

      height: 150px !important;

      border-radius: 5px;
    }

    /* mensaje whstapp */
    .send_whatsapp {
      width: 60%;
    }
  }
`;
const WhatsappFloat = () => {
  return (
    <Box>
      <div class='whatsapp_flotante'>
        <FontAwesomeIcon icon={faWhatsapp} />
        <i class='fab fa-whatsapp' aria-hidden='true'></i>
      </div>

      <div class=' send_whatsapp'>
        <div class='box_shared'>
          <div class='header_send_whatsapp'>
            <small class='text-center text-white'>
              <i>
                Enviar mensaje
                <strong>neider publicacion ejemplo pago</strong>
              </i>
            </small>
          </div>
          <textarea
            class='mensaje_burbuja form-control'
            placeholder='Escribir mensaje..'></textarea>
          <div class='text-center'>
            <button
              class='text-white enviar_mensaje'
              onclick='enviarWhatsapp(573185970802)'>
              <i class='fas fa-paper-plane' aria-hidden='true'></i>
            </button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default WhatsappFloat;

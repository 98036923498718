/** @format */

import { Route, Redirect } from "react-router-dom";
import { getDataUser } from "../helper/auth-helpers";

const ProtectedRoute = ({ Component, isAuthenticated, ...rest }) => {
  // console.log(...rest);
  const { permissions } = getDataUser();
  const setPermission = permissions.filter(
    (permission) =>
      permission.id === rest.permission || rest.permission?.id === undefined,
  );

  console.log(setPermission);

  return (
    <Route
      {...rest}
      render={(props) => {
        setPermission.lenght === 0 ? (
          <Component />
        ) : (
          <Redirect
            // to={{ pathname: "/entrar", state: { from: props.location } }}
            to='/entrar'
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;

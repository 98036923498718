/** @format */

import React, { useEffect, useState } from "react";
import { expresiones } from "../../../../helper/expresiones";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import InputTextTareaLabel from "../../../molecules/inputs/input-textarea-label";
import { ContainerTextarea } from "../style/styleForm";
import { initFormCreatePublication } from "../stateinitialforms/init-form-create-publication";
import validateInputText from "../../../../helper/validate-input-text";
import styled from "styled-components";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  @media (max-width: 840px) {
    display: block;
  }
`;
const Descriptions = ({ publication, title }) => {
  const [form, setForm] = useState();
  useEffect(() => {
    if (publication) {
      const { description, indication } = publication;
      setForm({
        description: {
          value: description,
        },
        indication: {
          value: indication,
        },
      });
    }
  }, [publication]);
  const handledChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          message: expresiones.message[e.target.name],
        },
      });
    }
  };

  return (
    <>
      <SubtitleForm text={title} />
      <Container>
        <InputTextTareaLabel
          label={{
            text: "Indicaciones",
          }}
          textarea={{
            placeholder: "Indicaciones para llegar",
            onChange: handledChange,
            message: form?.indication?.message,
            name: "indication",
            value: form?.indication?.value,
          }}
        />
        <InputTextTareaLabel
          label={{ text: "Descripción" }}
          textarea={{
            placeholder: "Descripción del servicio",
            onChange: handledChange,
            message: form?.description?.message,
            name: "description",
            value: form?.description?.value,
          }}
        />
      </Container>
    </>
  );
};

export default Descriptions;

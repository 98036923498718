/** @format */

import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import IconSocial from "../../atoms/icons/icon-social";
import HeadMainResponsivePublic from "./head-main-responsive-public";
import ItemMenuChildrenPublic from "./item-menu-children-public";
import ItemMenuPublic from "./item-menu-public";
const BoxRedes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);

  a > svg {
    text-align: center;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    height: 25px;
    width: 25px;
  }
  .svg-inline--fa.fa-w-14,
  .fa-w-16 {
    height: 25px;
    width: 25px;
  }
  div {
    cursor: pointer;
  }
`;

const MenuDesktopPublic = (props) => {
  const { login } = props;
  const socials = [
    {
      id: 1,
      url: "https://www.tiktok.com/@akunaipa",
      icon: faTiktok,
    },
    {
      id: 2,
      url: "https://www.tiktok.com/@akunaipa",
      icon: faFacebook,
    },
    {
      id: 3,
      url: "https://www.tiktok.com/@akunaipa",
      icon: faInstagram,
    },
  ];

  return (
    <div className='site-navbar'>
      <div className='container py-1'>
        <div className='row align-items-center contenedor_menu_android '>
          <HeadMainResponsivePublic socials={socials} movil={props.movil} />
          <div className='col-6 col-md-4 col-lg-9'>
            <nav className='site-navigation' role='navigation'>
              <ul className='site-menu js-clone-nav d-none d-lg-block'>
                <ItemMenuPublic to='/' text='Inicio' />
                <ItemMenuChildrenPublic
                  text='Preguntas frecuentes'
                  items={[
                    { to: "nosotros", text: "Nosotros" },
                    { to: "preguntas-frecuentes", text: "Como publicar" },
                    { to: "contacto", text: "Contacto" },
                  ]}
                />
                <ItemMenuChildrenPublic
                  text='Categorias'
                  items={[
                    { to: "hoteles", text: "Hoteles" },
                    { to: "restaurantes", text: "Restaurantes" },
                    { to: "expreriencias", text: "Experiancias" },
                    { to: "guias-locales", text: "Guias Locales" },
                  ]}
                />

                {login ? (
                  <>
                    <ItemMenuPublic text='Panel' to='/panel' />
                    <ItemMenuPublic
                      text='Cerrar sessión'
                      to='/panel/cerrar-sesion'
                    />
                  </>
                ) : (
                  <>
                    <ItemMenuPublic text='Entrar' to='/entrar' />
                    <ItemMenuPublic text='Registrarme' to='registrarme' />
                  </>
                )}

                <BoxRedes as='li'>
                  {socials.map((social) => (
                    <IconSocial {...social} />
                  ))}
                </BoxRedes>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MenuDesktopPublic;

/** @format */

import React from "react";
import styled from "styled-components";

const ValidationStyles = styled.div`
  color: ${(props) => props.theme.colors.primary};
  height: 10px;
  position: relative;
  font-size: 13px;
  margin-bottom: 10px;
`;

const ValidationInputText = (props) => {
  return <ValidationStyles {...props}>{props.message}</ValidationStyles>;
};

export default ValidationInputText;

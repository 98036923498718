/** @format */

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GlobalStyle } from "./globalstyle/GlobalStyle";
import { ThemeStyle } from "./globalstyle/ThemeStyle";
import { ThemeProvider } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import rutesMenuPanel from "./routes/rutes-menu-panel";
import rutesPublic from "./routes/rutes-public";
import ProtectedRoute from "./routes/protected-route";
import StartPublic from "./components/templates/public/star-public";

const App = (props) => {
  return (
    <ThemeProvider theme={ThemeStyle}>
      <GlobalStyle />
      <Router>
        <Switch>
          {rutesMenuPanel.map((ru) => (
            <ProtectedRoute
              permission={ru?.id}
              exact
              path={ru.rute}
              component={ru.component}
            />
          ))}

          {/* {rutesMenuPanel.map((ru) => (
            <Route exact path={ru.rute} component={ru.component} />
          ))} */}

          {/* --- */}
          {rutesPublic.map((rute) => (
            <Route exact path={rute.path} component={rute.component} />
          ))}
          {/* <Route exact path='/nav' component={BaseSidebar} /> */}
          {/* <Route exact path='/entrar' component={LoginUser} />
          <Route exact path='/registrarme' component={RegisterPublication} />
          <Route exact path='/panel' component={HomePanel} /> */}
          {/* {rut.map((ru) =>
            ru.component ? (
              <Route exact path={ru.path} component={ru.component} />
            ) : null,
          )} */}

          {/* <Route exact path='/context' component={RolesAndPermissions} /> */}
          {/* rutes cients */}
          {/* <Route path={rute.client.path}>
            <Route component={(props) => <RuteClient {...props} />} />
          </Route>

          <Route path={rute.user.path}>
            <Route component={(props) => <RuteClient {...props} />} />
          </Route> */}
          {/* rutes admin */}
          {/* <Route path={`${rute.admin.path}`}>
            <Route component={(props) => <RuteAdmin {...props} />} />
          </Route> */}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;

/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const IconSocial = (props) => {
  const { url, icon } = props;
  return (
    <Link to={url} target='_blank' className='text-success'>
      <FontAwesomeIcon icon={icon} size='lg' />
    </Link>
  );
};

export default IconSocial;

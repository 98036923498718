/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Badge } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { URL_PANEL, URL_SERVER } from "../../../const/const";
const Container = styled.div`
  padding: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 1%;
`;

const Img = styled.img`
  margin: 0 auto;
  margin-top: 3%;
  display: none;
  width: 70%;
`;
const Title = styled.p`
  font-size: 12px;
`;
const CardPublicationPanel = (props) => {
  // const publication = props.data;
  const { id, title, first_image, publication_state, categorie } = props.data;

  const getDate = () => {
    const allDate = new Date();
    const month =
      allDate.getMonth() < 9
        ? `0${allDate.getMonth() + 1}`
        : allDate.getMonth();
    const date = `${allDate.getFullYear()}/${month}/${allDate.getDate()}`;
    return date;
  };

  return (
    <div className='col-lg-3 mb-2'>
      <Container className='m-1'>
        <div className='col-lg-12'>
          <Title as={Card.Title} className='text-capitalize'>
            {title}
          </Title>
        </div>
        <div className='row'>
          <div className='col-lg-8 '>
            <Badge variant='info'>{categorie?.name}</Badge>
          </div>
          <div className='col-lg-4'>
            <Badge
              variant={`${
                publication_state?.id === 1
                  ? "success"
                  : publication_state?.id === 2
                  ? "warning"
                  : "danger"
              }`}>
              {publication_state?.name}
            </Badge>
          </div>
        </div>
        <Col md={12}>
          <Img
            src={first_image?.url || `${URL_SERVER}images/avatar/galeria.jpg`}
            alt={title}
            className='img-thumbnail rounded  mx-auto d-block'
          />
        </Col>
        <div className='row mt-3 text-center'>
          <div className='col-4 col-lg-4'>
            <Link
              to={`preview-publication/${id}`}
              className='btn btn-sm btn-success'>
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>
          <div className='col-4 col-lg-4'>
            <Link
              to={`${URL_PANEL}publication/edit/${id}`}
              className='btn btn-sm btn-warning'>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </div>
          <div className='col-4 col-lg-4'>
            <button className='btn btn-sm btn-danger'>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default CardPublicationPanel;

/** @format */

import { ContainerInputs } from "../style/styleForm";
import InputFileLabel from "../../../molecules/inputs/input-file-label";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import ImagePreviewChange from "../../../../helper/image-preview-change";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { deleteFetch } from "../../../../helper/fetchApi";

const ImagesPublications = ({ create, publication = {}, title }) => {
  const [quantityImages, setQuantityImage] = useState([]);
  const [images, setImages] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (create) {
      let countImage = Array.from({ length: 6 }, (v, i) => i);
      setQuantityImage(countImage);

      const viewCard = quantityImages.map((image, key) => (
        <InputFileLabel
          image={images[key] ? images[key].url : false}
          order={key + 1}
          label='Imagen Principal'
          onChange={ImagePreviewChange}
          name='image[]'
          visible={images[key] ? true : false}
          onDelete={handleDelete}
          idKey={key}
          idImage={images[key] ? images[key].id : false}
        />
      ));

      setCards(viewCard);
    }
  }, [create]);

  useEffect(() => {
    if (Object.entries(publication).length !== 0) {
      const listImages = publication.imagespublication;
      setImages(listImages);
      console.log("editar type");
      const typePublication = 1;
      if (typePublication) {
        let countImage = Array.from({ length: 12 }, (v, i) => i);
        setQuantityImage(countImage);
      } else {
        let countImage = Array.from({ length: 6 }, (v, i) => i);
        setQuantityImage(countImage);
      }
    }
  }, [publication]);

  const handleDelete = (id, key) => {
    swal({
      title: "Deseas eliminar la imagen ?",
      text: "Despues de eliminada no podra recuperarse",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFetch(`imgpublications/${id}`).then((res) => {
          if (res.success) {
            swal("Imagen eliminada correctamente", {
              icon: "success",
            });
            images.splice(key, 1);
            const newImage = images.map((im) => {
              return im;
            });
            setImages(newImage);
          }
        });
      }
    });
  };

  return (
    <>
      <SubtitleForm text={title || "Fotos"} />
      {/* {JSON.stringify(images)} */}
      <div className='col-lg-12 row'>
        {quantityImages.map((image, key) => (
          <InputFileLabel
            image={images[key] ? images[key].url : false}
            order={key + 1}
            label={{ text: "Imagen Principal" }}
            onChange={ImagePreviewChange}
            name='image[]'
            visible={images[key] ? true : false}
            onDelete={handleDelete}
            idKey={key}
            idImage={images[key] ? images[key].id : false}
            className='col-12 col-lg-3 mt-3'
          />
        ))}
      </div>
    </>
  );
};

export default ImagesPublications;

import BasePublic from "../../organisms/bases/base-public";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFetch } from "../../../helper/fetchApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import formatCurrency from "../../../helper/format-currency";
import SubtitleCategorie from "../../atoms/texts/subtitle-categorie";
import GroupCardsPublication from "../../organisms/cards/group-card-publication";
import { Accordion } from "react-bootstrap";

import ImageGallery from "react-image-gallery";
import WhatsappFloat from "../../organisms/whatsapp/whatsapp-float";
import { getItemStorage } from "../../../helper/local-storage";
const ViewPublication = (props) => {
    let { id } = useParams();
    const [gallery, setGallery] = useState();
    const [
        {
            continuos_service,
            closed,
            title,
            favorites_count,
            indication,
            description,
            imagespublication,
            municipalitie,
            open,
            price_min,
            prices = [],
            services = [],
        },
        setPublication,
    ] = useState({});
    useEffect(() => {
        getFetch(
            `publications/${atob(
                id
            )}?imagespublication=true&favorites_count=true&municipalitie-department=name&price_min=true&prices=true&services=true`
        ).then((response) => {
            if (response.success) {
                setPublication(response.data);
            }
        });
    }, [id]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getItemStorage("categories").then((response) => {
            if (response) {
                setCategories(response.list);
            }
        });
    }, []);

    useEffect(() => {
        if (imagespublication !== undefined && imagespublication !== null) {
            const n = imagespublication.map((res) => {
                return {
                    original: res.url,
                    thumbnail: res.url,
                };
            });

            setGallery(
                <ImageGallery showNav={false} lazyLoad={true} items={n} />
            );
        }
    }, [imagespublication]);

    const horary = (hourComplete, am = false) => {
        if (hourComplete) {
            const hour = hourComplete.split(":");
            // console.log(n);
            if (hour[0] < 12) {
                return `${hour[0]}:${hour[0]} am`;
            } else {
                return `${hour[0]}:${hour[0]} pm`;
            }
        }
    };
    return (
        <BasePublic title="Nosotros" carousel formsearch>
            <div class="mt-3" id="details">
                <div class="row">
                    <div class="col-lg-6">
                        {gallery}
                        {/* <CarouselDetails images={imagespublication} type={0} /> */}
                    </div>
                    {/* {JSON.stringify(imagespublication)} */}

                    <div class="col-lg-6 mt-1">
                        <h3 class="nom_pu_info text-success mt-3 mb-3">
                            {title}
                        </h3>

                        <span class="property-location d-block mb-2 mt-2 text-capitalize">
                            <FontAwesomeIcon icon={faMapMarkedAlt} />{" "}
                            {municipalitie?.name} -{" "}
                            {municipalitie?.department?.name}
                        </span>

                        <strong class="property-price text-warning mb-3 d-block ">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />({favorites_count})
                        </strong>
                        <b>Horario de atención</b>

                        <div class="mt-2 mb-4">
                            {continuos_service
                                ? "24 Horas"
                                : `${horary(open, true)} - ${horary(closed)}`}
                        </div>

                        <strong class="h3 mb-3 text-success mb-2">
                            {price_min ? formatCurrency(price_min?.price) : ""}
                        </strong>

                        <div class="lista_precios table-responsive mt-4"></div>

                        <Accordion defaultActiveKey="0" className="mb-2">
                            {prices.length > 0 ? (
                                <Accordion.Toggle
                                    as="div"
                                    className="btn btn-primary mt-2"
                                    variant="link"
                                    eventKey="1"
                                >
                                    ver mas precios
                                </Accordion.Toggle>
                            ) : (
                                ""
                            )}
                            <Accordion.Collapse eventKey="1">
                                <table class="table table-sm mt-2">
                                    <thead class="table-info">
                                        <tr>
                                            <th scope="col">Precios</th>

                                            <th scope="col">Caracteristica</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {prices.map((price) => (
                                            <tr>
                                                <th scope="col">
                                                    {formatCurrency(
                                                        price.price
                                                    )}
                                                </th>

                                                <th scope="col">
                                                    {price.name}
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                </div>
                <div className="mt-3 row">
                    <h4 class="h4 text-black col-lg-12">Servicios</h4>
                    {services.map((service) => (
                        <div className="col-6 col-lg-2">
                            {service.name}
                            <img
                                src={service.img}
                                alt={service.name}
                                width="100%"
                            />
                        </div>
                    ))}
                </div>
                <div class="mt-3">
                    <h4 class="h4 text-black col-lg-12">Indicaciones</h4>

                    <p class="col-lg-12">{indication}</p>

                    <h4 class="h4 text-black col-lg-12 ">Descripcion</h4>

                    <p class="col-lg-12">{description}</p>
                </div>{" "}
            </div>
            {/* <WhatsappFloat /> */}

            {categories.map((categorie) => (
                <>
                    <SubtitleCategorie text={categorie.name} />
                    <GroupCardsPublication {...categorie} />
                </>
            ))}
        </BasePublic>
    );
};

export default ViewPublication;

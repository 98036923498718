import React, { useEffect, useState } from "react";
import { getFetch } from "../../../helper/fetchApi";
import BasePanel from "../../organisms/bases/base-panel";
import GroupCardPublicationPanel from "../../organisms/cards/group-card-publication-panel";
import InputTextLabel from "../../molecules/inputs/input-text-label";

const PublicationsActives = (props) => {
    const [publication, setPublication] = useState([]);

    useEffect(() => {
        getFetch(
            "publications/user?publication_state=true&categorie=true&parent_category=true&paginate=20&first_image=true&filter_state=1"
        ).then((res) => {
            if (res.data) {
                setPublication(res.data);
            }
        });
    }, []);
    const url =
        "publications/user?publication_state=name&categorie=true&parent_category=true&paginate=20&first_image=true";
    const cargar = () => {
        getFetch(url).then((res) => {
            if (res.data) {
                setPublication(res.data);
            }
        });
    };

    const search = () => {};
    const changePage = (id) => {
        getFetch(`${url}&page=${id}`).then((services) => {
            if (services.success) {
                setPublication(services.data);
            }
        });
    };
    return (
        <div>
            <BasePanel page="Publicaciones Activas">
                <form onSubmit={search}>
                    <InputTextLabel
                        select={{
                            text: "Buscar",
                        }}
                        input={{
                            type: "search",
                        }}
                    />
                </form>
                <button onClick={() => cargar()}>cargar</button>
                {/* {JSON.stringify(publication)} */}
                <GroupCardPublicationPanel
                    change={changePage}
                    data={publication}
                />
            </BasePanel>
        </div>
    );
};

export default PublicationsActives;

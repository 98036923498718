/** @format */

import React from "react";
import styled from "styled-components";

const TextSubtitleForm = styled.p`
  color: ${(props) => props.color || props.theme.colors.secondary};
  font-weight: bold;
  &:first-letter {
    text-transform: uppercase;
  }
  text-align: ${(props) => props.align || "center"};
  width: 100%;
`;

const SubtitleForm = (props) => {
  return <TextSubtitleForm>{props.text}</TextSubtitleForm>;
};

export default SubtitleForm;

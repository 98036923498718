/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MenuMovilPublic from "../../molecules/menus/menu-movil-public";
import MenuDesktopPublic from "../../molecules/menus/main-desktop-public";
import { getVerify } from "../../../helper/fetchApi";
import { getDataUser } from "../../../helper/auth-helpers";
const ContainerMenu = styled.div`
  width: 100%;
  position: absolute;
  top: 10px;
`;

const MainPublic = (props) => {
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const { login } = getDataUser();
    if (login) {
      setIsLogin(true);
    }
    // getVerify("auth/verify", true).then((response) =>
    //   response.success ? setIsLogin(true) : setIsLogin(false),
    // );
  }, []);
  return (
    <ContainerMenu>
      <MenuMovilPublic login={isLogin} movil={{ open: open, set: setOpen }} />
      <MenuDesktopPublic login={isLogin} movil={{ open: open, set: setOpen }} />
    </ContainerMenu>
  );
};

export default MainPublic;

/** @format */

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import Carousel from "../../atoms/images/carousel";
import formatCurrency from "../../../helper/format-currency";
import { useState } from "react";
import { deleteFetch, postFetch } from "../../../helper/fetchApi";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const BoxCardPublication = styled.div`
  padding: 5%;
  .card-favorite {
    box-shadow: ${(props) => props.theme.shadow.card};
    width: 100%;
    height: auto;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  .box-favorite {
    background: ${(props) => props.theme.colors.tertiary};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10px;
    right: 1%;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
  .box-info-card {
    padding: 1%;
  }
  .title-card {
    font-size: 14px;
    font-weight: bold;
  }
  .text-card-name {
    font-size: 11px;
  }
  .box-carousel {
    margin: 5%;
  }
`;

const CardPublication = (props) => {
  const {
    categorie,
    id,
    is_favorite_count,
    imagespublication,
    favorites_count,
    municipalitie,
    price_min,
    title,
    type,
  } = props.publication;

  const [isFavorite, setIsFavorite] = useState(is_favorite_count);
  const replacer = (str, replace = "-") => {
    var i = 0,
      strLength = str.length;
    for (i; i < strLength; i++) {
      str = str.replace(" ", replace);
    }

    return str;
  };
  const add_favorite = () => {
    if (!isFavorite) {
      const n = new FormData();
      n.append("publication_id", id);
      postFetch("favorites", n).then((response) =>
        swal(JSON.stringify(response)),
      );
      setIsFavorite(!isFavorite);
    } else {
      deleteFetch(`favorites/${id}`).then((response) =>
        swal(JSON.stringify(response)),
      );
      setIsFavorite(!isFavorite);
    }
  };
  return (
    <div className={`p-1 ${props.className || ""}`}>
      <div class={`m-1 card car-hiddent`}>
        <div class='p-1'>
          <div
            class={`property-favorite ${
              isFavorite ? "bg-info text-success" : "bg-primary"
            }    favorito_1`}
            onClick={add_favorite}>
            <FontAwesomeIcon icon={faHeart} />
            <span class='fas fa-heart' aria-hidden='true'></span>
          </div>

          <h5 class='card-title mt-2'>{title}</h5>
          <Carousel type={type} items={imagespublication} />
          <div class='text-warning estrellas mt-2 '>
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />({favorites_count})
          </div>
          {categorie.name}
          <p class='card-text mt-2'>
            <i class='fas fa-map-marker-alt' aria-hidden='true'></i>{" "}
            {municipalitie?.name}- {municipalitie?.department?.name}
          </p>

          <div class='row '>
            <div class='col-lg-8 col-8 '>
              <strong class='text-success precio_miniatura' title='0'>
                {formatCurrency(price_min?.price)}
              </strong>
            </div>

            <div class='col-lg-4 col-4 text-center '>
              <Link
                to={`/${replacer(
                  categorie?.parent_categorie?.name,
                ).toLowerCase()}/${replacer(
                  categorie.name,
                ).toLowerCase()}/${replacer(
                  municipalitie?.name,
                ).toLowerCase()}--${replacer(
                  municipalitie?.department?.name,
                ).toLowerCase()}/${replacer(title).toLowerCase()}/${btoa(id)}`}
                class='btn btn-sm text-white btn-success btn-block'>
                Ver
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPublication;

/** @format */

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import IconSocial from "../../atoms/icons/icon-social";
import { URL_SERVER } from "../../../const/const";
const BoxRedes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);

  a > svg {
    text-align: center;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    height: 25px;
    width: 25px;
  }
  .svg-inline--fa.fa-w-14,
  .fa-w-16 {
    height: 25px;
    width: 25px;
  }
  div {
    cursor: pointer;
  }
`;
const HeadMainResponsivePublic = (props) => {
  const { socials } = props;
  return (
    <>
      <div className='col-6 col-md-8 col-lg-3'>
        <h1 className='mb-0'>
          <Link to='/' className='text-white h2 mb-0'>
            <img
              className='logo'
              src={`${URL_SERVER}images/system/logo-akunaipa.png`}
              alt='logo akunaipa'
            />
          </Link>
        </h1>
      </div>
      <BoxRedes className='col-6 d-lg-none ml-md-0 mr-auto py-3'>
        {socials.map((social) => (
          <IconSocial {...social} />
        ))}
        <div
          to='#'
          onClick={() => props.movil.set(!props.movil.open)}
          className='site text-white'>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </BoxRedes>
    </>
  );
};

export default HeadMainResponsivePublic;

import React, { Suspense } from "react";
import BasePanel from "../../organisms/bases/base-panel";
import GroupCardsFavorite from "../../organisms/cards/group-cards-favorite";
const MyFavorites = (props) => {
    return (
        <div>
            <BasePanel page="Mis Favoritos">
                <Suspense fallback={<div>Loading...</div>}>
                    <GroupCardsFavorite />
                </Suspense>
            </BasePanel>
        </div>
    );
};

export default MyFavorites;

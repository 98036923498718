/** @format */

import React from "react";
import InputText from "../../atoms/inputs/input-text";
import LabelInput from "../../atoms/labels/label-input";
import ValidationInputText from "../../atoms/texts/validation-input-text";
import PropTypes from "prop-types";
import styled from "styled-components";
const Container = styled.div`
  padding: 1% 2%;
`;
const InputTextLabel = (props) => {
  const { className, label, input, message } = props;
  return (
    <Container className={className}>
      <LabelInput {...label} />
      <InputText {...input} />
      <ValidationInputText message={message} />
    </Container>
  );
};

InputTextLabel.propTypes = {
  label: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default InputTextLabel;

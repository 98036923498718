/** @format */

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { URL_SERVER } from "../../../const/const";
import AvatarUser from "../../atoms/images/avatar-user";
import IndicatorPageText from "../../atoms/texts/indicator-page-text";

const Container = styled.div`
  display: grid;
  background: ${(props) => props.theme.colors.primary};
  grid-template-columns: 50% 50%;
  height: 50px;
  padding: 0 3%;
  > a,
  a > img {
    display: none;
  }
  @media (max-width: 840px) {
    grid-template-columns: 50% 40% 10%;
    > a,
    a img {
      display: block;
    }
  }
`;

const NavHeadPanel = (props) => {
  return (
    <Container>
      <Link to='/'>
        <img
          className='logo'
          src={`${URL_SERVER}images/system/logo-akunaipa.png`}
          alt='logo akunaipa'
          width='100%'
        />
      </Link>
      <IndicatorPageText text={props.page} />
      <AvatarUser />
    </Container>
  );
};

export default NavHeadPanel;

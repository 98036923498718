import React, { Suspense, lazy } from "react";
import BasePanel from "../../organisms/bases/base-panel";

const FormCreatePublication = lazy(() =>
    import("../../organisms/forms/form-create-publication")
);

const CreatePublication = (props) => {
    return (
        <div>
            <BasePanel page="Crear publicación">
                <Suspense fallback={<div>cargando...</div>}>
                    <FormCreatePublication />
                </Suspense>
            </BasePanel>
        </div>
    );
};

export default CreatePublication;

/** @format */

const infoUser = JSON.parse(window.localStorage.getItem("USER"));

let avatar = "";
let email = "";
let lastname = "";
let name = "";
let phone = "";
let whatsapp = "";
let user = "";

if (infoUser) {
  avatar = infoUser.avatar;
  email = infoUser.email;
  lastname = infoUser.lastname;
  name = infoUser.name;
  phone = infoUser.phone;
  whatsapp = infoUser.whatsapp;
  user = infoUser.user;
}

export { avatar, email, lastname, name, phone, whatsapp, user };

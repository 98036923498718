import BasePublic from "../../organisms/bases/base-public";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import { useState } from "react";
import { expresiones } from "../../../helper/expresiones";
import { postFetch } from "../../../helper/fetchApi";

const Data = (props) => {
    const { title, description } = props;
    return (
        <>
            <p class="mb-0 font-weight-bold">{title}</p>
            <p class="mb-4">{description}</p>
        </>
    );
};

const Contact = (props) => {
    const informations = [
        {
            id: 1,
            title: "Dirección",
            description: "Clle 67 N 5 - 26 Int 7 - 303 Ibagué Tolima Colombia",
        },
        {
            id: 2,
            title: "Telefono",
            description: "+57 321 983 6582",
        },
        {
            id: 3,
            title: "Direccion Correo",
            description: "contacto@akunapa.com",
        },
    ];
    const [form, setForm] = useState({ data: {} });
    const change = (e) => {
        if (expresiones.validate[e.target.name].test(e.target.value)) {
            setForm({
                ...form,
                data: {
                    ...form.data,
                    [e.target.name]: {
                        value: e.target.value,
                        validate: true,
                    },
                },
                message: false,
            });
        }
    };

    const submit = (e) => {
        e.preventDefault();
        alert("pendente fetch send");
        if (
            form.data?.name?.validate &&
            form.data?.message?.validate &&
            form.data?.theme?.validate &&
            form.data?.email?.validate
        ) {
            const formulary = new FormData(e.target);
            postFetch("contact", formulary);
        } else {
            setForm({
                ...form,
                message: true,
            });
        }
    };

    return (
        <BasePublic title="Nosotros" carousel formsearch>
            <div class="site-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-lg-8 mb-5 mt-5">
                            <form
                                onSubmit={submit}
                                class="p-1 pt-2 pb-2 bg-white border boder_formulario col-lg-12 row"
                                id="formularioContacto"
                            >
                                <InputTextLabel
                                    label={{ text: "Nombre completo" }}
                                    input={{
                                        placeholder: "Nombre",
                                        name: "name",
                                        onChange: change,
                                    }}
                                />
                                <InputTextLabel
                                    label={{ text: "Correo" }}
                                    input={{
                                        placeholder: "example@gmail.com",
                                        name: "email",
                                        onChange: change,
                                    }}
                                />

                                <InputTextLabel
                                    label={{ text: "Tema" }}
                                    input={{
                                        placeholder: "Tema",
                                        name: "theme",
                                        onChange: change,
                                    }}
                                />
                                <div class="col-lg-3"></div>

                                <div class="col-lg-6 form-group">
                                    <div class="col-md-12">
                                        <label
                                            class="font-weight-bold"
                                            for="message"
                                        >
                                            Mensaje
                                        </label>

                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="5"
                                            onChange={change}
                                            class="form-control "
                                            placeholder="Dejanos tu mensaje..."
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center mt-2 mb-2">
                                    {form?.message
                                        ? "Rellena el formulario correctamente"
                                        : ""}
                                </div>
                                <div class="col-lg-12 text-center">
                                    <button className="btn btn-primary">
                                        Enviar Mensaje
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div class="col-lg-4 mt-5">
                            <div class="p-4 mb-3 bg-white">
                                <h3 class="h6 text-black mb-3 text-uppercase">
                                    Datos de contacto
                                </h3>
                                {informations.map((information) => (
                                    <Data {...information} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasePublic>
    );
};

export default Contact;

/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const CardActions = (props) => {
  const data = props.data;
  const [open, setOpen] = useState(false);

  return (
    <div className='col-lg-3 mb-2'>
      <div className='card '>
        <div className='card-header'>
          <div className='row'>
            <div className='col-md-10'>
              <div className='text-capitalize card-title'>{data.name}</div>
            </div>
            <div className='col-md-2'>
              <div className='badge badge-secondary'>
                {data.permissions_count}
              </div>
            </div>
            <div className='col-lg-12 text-center'>
              <button
                className=' btn-sm btn-primary '
                onClick={() => props.onClick(data.id)}>
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardActions;

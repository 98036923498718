import React, { useState, useEffect } from "react";

import { getFetch } from "../../../helper/fetchApi";
import GroupCardsUsers from "../../organisms/cards/group-cards-users";
import ModalInformationUser from "../../organisms/modals/modal-information-user";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import InputSelectLabel from "../../molecules/inputs/input-select-label";
import addValueSelect from "../../../helper/addValueSelect";
import { Row } from "react-bootstrap";
import BasePanel from "../../organisms/bases/base-panel";
import LinkExportExcel from "../../molecules/link-export-excel";
const ListModerators = (props) => {
    const [filtersQuery, setFiltersQuery] = useState({});
    const [modal, setModal] = useState(false);
    const filters = [
        {
            label: "Todos",
            value: "all",
        },
        {
            label: "correo",
            value: "email",
        },
        {
            label: "usuario",
            value: "user",
        },
        {
            label: "nombre",
            value: "name",
        },
    ];
    const filterDate = [
        {
            label: "No",
            value: "false",
        },
        {
            label: "Si",
            value: "true",
        },
    ];
    const initSelect = {
        options: [{ value: "", label: "" }],
        value: [{ value: "", label: "" }],
    };
    const [users, setUsers] = useState({ data: [] });
    const [filter, setFilter] = useState(initSelect);
    const [optionsFilterDate, setOptionsFilterDate] = useState(initSelect);

    useEffect(() => {
        setFilter(addValueSelect(filters, "label", "value"));
        setOptionsFilterDate(addValueSelect(filterDate, "label", "value"));
        getFetch(
            `user/role/moderador?favorites_count=true&user_state=true&order_by=ASC&paginate=32&q=${
                filtersQuery?.q || false
            }&filter_q=${filtersQuery?.filter_q || "all"}&filter_date=${
                filtersQuery?.filter_date || false
            }&from=${filtersQuery?.from || false}&by=${
                filtersQuery?.by || false
            }&type=moderador`
        ).then((listUser) =>
            listUser.success ? setUsers(listUser.data) : null
        );
    }, []);
    useEffect(() => {
        if (filtersQuery?.q !== "" && filtersQuery?.q !== undefined) {
            getFetch(
                `user/role/moderador?favorites_count=true&user_state=true&order_by=ASC&paginate=32&q=${
                    filtersQuery?.q || false
                }&filter_q=${filtersQuery?.filter_q || "all"}&filter_date=${
                    filtersQuery?.filter_date || false
                }&from=${filtersQuery?.from || false}&by=${
                    filtersQuery?.by || false
                }&type=moderador`
            ).then((listUser) =>
                listUser.success ? setUsers(listUser.data) : null
            );
        }
    }, [filtersQuery]);

    const changePage = (id) => {
        getFetch(
            `user/role/moderador?favorites_count=true&user_state=true&order_by=ASC&paginate=32&q=${
                filtersQuery?.q || false
            }&filter_q=${filtersQuery?.filter_q || "all"}&filter_date=${
                filtersQuery?.filter_date || false
            }&from=${filtersQuery?.from || false}&by=${
                filtersQuery?.by || false
            }&type=moderador&page=${id}`
        ).then((listUser) =>
            listUser.success ? setUsers(listUser.data) : null
        );
    };
    const [form, setForm] = useState({});
    const [information, setInformation] = useState({});

    const openModal = (id) => {
        setModal(true);

        const user = users.data.find((user) => user.id === id);
        const llaves = Object.keys(user);
        const l = {};
        llaves.map(
            (llave) => (l[llave] = { value: user[llave], validate: true })
        );

        setInformation(user);

        setForm({
            edit: true,
            ...l,
        });
    };

    const changeSelect = (e, j) => {
        setFiltersQuery({
            ...filtersQuery,
            [j.name]: e.value,
        });
    };

    const changeText = (e) => {
        setFiltersQuery({
            ...filtersQuery,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <BasePanel page="Moderadores">
                <Row>
                    <InputTextLabel
                        className="col-6 col-lg-3"
                        label={{ text: "Buscar" }}
                        input={{
                            type: "search",
                            placeholder: "Buscar",
                            onChange: changeText,
                            name: "q",
                        }}
                    />

                    <InputSelectLabel
                        className="col-6 col-lg-3"
                        label={{
                            text: "Filtro",
                        }}
                        select={{
                            options: filter?.options,
                            onChange: changeSelect,
                            name: "filter_q",
                            default: filter?.value,
                        }}
                    />

                    <InputSelectLabel
                        className="col-6 col-lg-3"
                        label={{
                            text: "Filtrar Fecha",
                        }}
                        select={{
                            options: optionsFilterDate?.options,
                            onChange: changeSelect,
                            name: "filter_date",
                            default: optionsFilterDate?.value,
                        }}
                    />

                    <InputTextLabel
                        className="col-6 col-lg-3"
                        label={{ text: "Desde" }}
                        input={{
                            type: "date",
                            onChange: changeText,
                            name: "from",
                        }}
                    />
                    <InputTextLabel
                        className="col-6 col-lg-3"
                        label={{ text: "Hasta" }}
                        input={{
                            type: "date",
                            name: "by",
                            onChange: changeText,
                        }}
                    />

                    <LinkExportExcel
                        url={`user/export?favorites_count=true&user_state=true&order_by=ASC&paginate=32&q=${
                            filtersQuery?.q || false
                        }&filter_q=${
                            filtersQuery?.filter_q || "all"
                        }&filter_date=${
                            filtersQuery?.filter_date || false
                        }&from=${filtersQuery?.from || false}&by=${
                            filtersQuery?.by || false
                        }&type=moderador`}
                    />
                </Row>
                {/* {JSON.stringify(users)} */}
                <div>Resultados: {users.total}</div>
                <GroupCardsUsers
                    users={users}
                    openModal={openModal}
                    change={changePage}
                />

                <ModalInformationUser
                    form={{ data: form, set: setForm }}
                    openModal={modal}
                    setOpenModal={setModal}
                    // modal={{ open: modal, set: setModal }}
                    information={information}
                />
            </BasePanel>
        </>
    );
};

export default ListModerators;

import FormLogin from "../../organisms/forms/form-login";
import BasePublic from "../../organisms/bases/base-public";
import FormRecoverPassword from "../../organisms/forms/form-recover-password";

const RecoverPassword = (props) => {
    return (
        <BasePublic title="Recuperar cuenta - akunaipa">
            <FormRecoverPassword />
        </BasePublic>
    );
};

export default RecoverPassword;

/** @format */

import MenuInicio from "../menus/main-public";
import styled from "styled-components";
import CarouselMain from "../../molecules/carousel-main";
import FormSearchCarousel from "../../molecules/form-search-carousel";
import FooterPublic from "../footer/footer-public";
import { URL_SERVER } from "../../../const/const";
import { useEffect } from "react";
import { getFetch } from "../../../helper/fetchApi";
import { getItemStorage, setItemStorage } from "../../../helper/local-storage";

const BoxContainer = styled.div`
  border-radius: 10px;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 840px) {
    width: 100%;
  }
`;
const ContainerBody = styled.div`
  top: 0;
  background-image: url("${URL_SERVER}images/system/background-login.svg");
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
  @media (max-width: 840px) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const CarouselBox = styled.div`
  position: relative;
`;

const BasePublic = (props) => {
  useEffect(() => {
    getItemStorage("categories").then((response) => {
      if (!response) {
        getFetch("categories").then((response) => {
          if (response.success) {
            setItemStorage({
              list: response.data,
            });
          }
        });
      }
    });
  }, []);
  document.title = `${props.title} - akunaipa ` || "akunaipa";
  return (
    <ContainerBody>
      {props.carousel ? (
        <CarouselBox>
          <MenuInicio />
          {props.carousel ? <CarouselMain /> : ""}
          {props.formsearch ? <FormSearchCarousel /> : ""}
        </CarouselBox>
      ) : (
        <MenuInicio />
      )}
      <BoxContainer className='container'>{props.children}</BoxContainer>
      <FooterPublic />
    </ContainerBody>
  );
};

export default BasePublic;

/** @format */

const CarouselMain = () => {
  const url = "http://192.168.0.187:8000/images/system/carousel/";
  const images = [
    {
      id: 1,
      url_desktop: url + "carousel-akunaipa-inicio-desktop.jpg",
      url_movil: url + "carousel-akunaipa-inicio-movil.jpg",
      alt: "imagen 1",
    },
  ];
  return (
    <div
      id='carouselExampleControls'
      className='carousel css_carousel slide '
      data-ride='carousel'>
      <div className='carousel-inner  '>
        <div className='carousel-item carousel_header carousel-item-next carousel-item-left'>
          {images.map((img, key) => (
            <div
              className={`carousel-item carousel_header ${
                key === 0 ? "active" : ""
              }`}>
              <img
                loading='lazy'
                class='d-block w-100 h-100 '
                src={img.url_desktop}
                srcset={` ${img.url_desktop} 300w, ${img.url_movil} 840w, ${img.url_desktop} 1x`}
                alt={img.alt}
              />
            </div>
          ))}
        </div>
      </div>
      <a
        className='carousel-control-prev'
        href='#carouselExampleControls'
        role='button'
        data-slide='prev'>
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='sr-only'>Previous</span>
      </a>
      <a
        className='carousel-control-next'
        href='#carouselExampleControls'
        role='button'
        data-slide='next'>
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='sr-only'>Next</span>
      </a>
    </div>
  );
};

export default CarouselMain;

/** @format */

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import addValueSelect from "../../helper/addValueSelect";
import { getFetch } from "../../helper/fetchApi";
import { getItemStorage } from "../../helper/local-storage";
import InputSelectLabel from "./inputs/input-select-label";
import InputTextLabel from "./inputs/input-text-label";

const Input = styled.div`
  input {
    height: 42px;
  }
`;
const ResponseSearch = styled.div`
  position: absolute;
  z-index: 999;
  background: #ffffff;
  margin-top: 5px;
  border-radius: 15px;
  box-shadow: -1px 2px 18px -5px rgba(0, 0, 0, 0.75);

  * a {
    padding: 2%;
    margin: 0;
  }

  a :hover {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: absolute;
  bottom: 40%;
  form {
    border-radius: 5px;
    background: rgba(54, 78, 104, 0.5);
    position: relative;
    z-index: 999;
    padding: 10px 20px;
    height: auto;
    box-shadow: 0 0 30px 0 rbga(0, 0, 0, 1);
  }

  form * label {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #fff;
    font-weight: bold;
  }

  form .form-control {
    border: none;
  }

  form .btn[type="submit"] {
    height: 42px;
  }

  @media (max-width: 840px) {
    bottom: 25%;
  }
`;

const FormSearchCarousel = () => {
  const [categories, setCategories] = useState([]);

  const [open, setOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({});

  useEffect(() => {
    getItemStorage("categories").then((response) => {
      if (response) {
        const listCategories = [
          {
            id: 0,
            name: "Todos",
          },
          ...response.list,
        ];

        setCategories(addValueSelect(listCategories));
      }
    });
  }, []);
  const changeSelect = (e) => {
    setCategories({
      ...categories,
      value: [e],
    });

    setForm({
      ...form,
      data: {
        ...form.data,
        categorie_id: {
          label: e.value !== 0 ? e.label.toLowerCase() : "all",
          value: e.value,
        },
      },
    });

    getFetch(
      `publications/search/autocomplete?q=${form?.data?.q?.value}&categorie_id=${e.value}`,
    ).then((response) => (response.success ? setResult(response.data) : ""));
    setOpen(true);
    // if (form?.data?.q?.value > 3) {
    // }
  };
  const submit = (e) => {
    e.preventDefault();
  };
  const change = (e) => {
    setForm({
      ...form,
      data: {
        [e.target.name]: {
          value: e.target.value,
        },
      },
    });

    if (e.target.value.length > 3) {
      setOpen(true);
      getFetch(
        `publications/search/autocomplete?q=${e.target.value}&categorie_id=${
          form.data.categorie_id?.value || "all"
        }`,
      ).then((response) => (response.success ? setResult(response.data) : ""));
    }
  };

  return (
    // site-section site-section-sm pb-0
    <Container className='mt-1'>
      <div className='container'>
        <form onSubmit={submit} className=' row '>
          <Input
            as={InputTextLabel}
            label={{ text: "Buscar" }}
            input={{
              placeholder: "Ciudad..",
              onChange: change,
              name: "q",
            }}
            className='col-lg-6'
          />
          <InputSelectLabel
            label={{ text: "Tipo" }}
            select={{
              ...categories,
              onChange: changeSelect,
            }}
            isSearchable={false}
            className='col-12 col-lg-4'
          />

          <div className='col-lg-2 col-12 mt-3 text-right'>
            <label htmlFor='offer-types'></label>

            <input
              type='submit'
              className=' btn-sm btn btn-success btn-block text-white btn_buscar rounded-3'
              value='Buscar'
            />
          </div>
        </form>
        <div className='row '>
          <ResponseSearch
            className={`col-11 col-lg-5 ${open ? "d-block" : "d-none"}`}>
            <div class='col-lg-12'>
              {result.map(
                ({ id: municipalitie_id, name: municipalitie, department }) => (
                  <div onClick={() => setOpen(false)}>
                    <Link
                      to={`/s/${municipalitie}--${department.name}--${
                        department.nation.name
                      }/${form?.data?.categorie_id?.label || "all"}/?q=${
                        form?.data?.q?.value
                      }&categorie_id=${
                        form?.data?.categorie_id?.value || "all"
                      }&municipalitie_id=${municipalitie_id}`}
                      class='property-location d-block mt-1 mb-1 text-capitalize'>
                      <FontAwesomeIcon icon={faMapMarker} />
                      <i
                        class='fas fa-map-marker-alt'
                        aria-hidden='true'></i>{" "}
                      {municipalitie} - {department.name}
                    </Link>
                  </div>
                ),
              )}
            </div>
          </ResponseSearch>
          {/* {JSON.stringify(result)} -- {JSON.stringify(form)}{" "} */}
        </div>
      </div>
    </Container>
  );
};

export default FormSearchCarousel;

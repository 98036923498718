/** @format */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconStyle = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  font-size: 16px;
  height: 24px;
  text-align: center;
  width: 24px;
  right: 2%;
  top: 10px;
`;

const Icon = (props) => {
  return (
    <IconStyle message={props.message}>
      <FontAwesomeIcon icon={props.icon} />
    </IconStyle>
  );
};

Icon.propTypes = {
  icon: PropTypes.object.isRequired,
};

export default Icon;

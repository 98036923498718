/** @format */

import React, { useEffect, useState } from "react";
import { getDataUser } from "../../../helper/auth-helpers";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faList,
  faUserAlt,
  faUser,
  faBars,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import rutesMenuPanel from "../../../routes/rutes-menu-panel";
import { URL_PANEL, URL_SERVER } from "../../../const/const";
import CloseSession from "../../templates/panel/close-session";

const ContainerItems = styled.div``;

const ItemMenuFooter = (props) => {
  const { rute, icon } = props.item;

  return (
    <Link to={rute}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  );
};

const ItemMenuDesktop = (props) => {
  const { rute, icon, text } = props.item;

  return (
    <Link
      to={rute}
      id='menuPanelEscritorio__inicio'
      className='list-group-item nav-link stretched-link'>
      <span className='menuPanelEscritorio__icon'>
        <FontAwesomeIcon icon={icon} />
      </span>
      {text}
    </Link>
  );
};

const ContainerFooter = styled.div`
  position: fixed;
  color: #112236;
  font-size: 30px;
  width: 100%;
  height: 52px;
  background: #ff6b00;
  bottom: 0%;
  filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.25));
  z-index: 99999999;
  padding: 0px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  display: none;

  a {
    width: 50%;
    height: 50px;
    margin: 0 0 0 0;
    padding: 0;
    display: inline-block;
  }
  @media (max-width: 840px) {
    display: block;
    a {
      width: 25%;
      text-align: center;
      height: 52px;
      line-height: 52px;
    }
  }
`;

const MenuFooter = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  return (
    <ContainerFooter>
      <div className='cont'>
        {items.map((item) =>
          [1, 31].filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuFooter item={item} />
          ) : (
            ""
          ),
        )}
        {/* {items.map((item) => (
          <ItemMenuFooter item={item} />
        ))} */}
      </div>
    </ContainerFooter>
  );
};

const BoxTwo = styled.div`
  position: fixed;
  display: flex;
  bottom: 3%;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  font-size: 38px;
  z-index: 9999;
  right: 5%;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 50%);
  > svg {
    margin: auto;
  }
`;
const MenuFooterTwo = (props) => {
  return (
    <>
      <BoxTwo onClick={() => props.setOpen(!props.open)} className='bg-primary'>
        <FontAwesomeIcon icon={faBars} />
      </BoxTwo>
    </>
  );
};
const LogoPanel = styled.div``;

const Container = styled.div`
  height: 110vh;
  border-radius: 0px 10px 10px 0px;
  background: #f3f3f3;
  .logo-panel {
    margin-top: 8%;
    text-align: center;
  }
  .logo-panel * img {
    width: 80%;
  }
  .container-items {
    height: 75vh;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 10px;
    }
    &::-webkit-scrollbar-button:increment {
      display: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #797979;
      border-radius: 20px;
      border: 2px solid #f1f2f3;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  @media (max-width: 840px) {
    position: fixed;
    z-index: 999;
    width: 80%;
    top: 5%;
    left: 10%;
    height: auto;
    border-radius: 5%;
    display: none;
    background: transparent;
    > .logo-panel {
      display: none;
    }

    .container-items {
      height: 70vh;
      overflow: auto;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
    }
    /* display: none; */
  }
`;

const ContainerMovil = styled.div`
  height: 110vh;
  border-radius: 0px 10px 10px 0px;
  background: #f3f3f3;
  .logo-panel {
    margin-top: 8%;
    text-align: center;
  }
  .logo-panel * img {
    width: 80%;
  }
  .container-items {
    height: 75vh;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 10px;
    }
    &::-webkit-scrollbar-button:increment {
      display: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #797979;
      border-radius: 20px;
      border: 2px solid #f1f2f3;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  @media (max-width: 840px) {
    position: fixed;
    z-index: 999;
    width: 80%;
    top: 5%;
    left: 10%;
    height: auto;
    border-radius: 5%;
    background: transparent;
    > .logo-panel {
      display: none;
    }
    .container-items {
      height: 70vh;
      overflow: auto;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
    }
    /* display: none; */
  }
`;

const SubMenu = ({ icon, text, items, permissions_id = [] }) => {
  return (
    <li className='dropdown'>
      <Link
        href='#'
        className='list-group-item  dropdown-toggle'
        data-toggle='dropdown'>
        {icon ? <FontAwesomeIcon icon={icon} /> : ""} {text}
      </Link>

      <ul className='dropdown-menu'>
        {items.map((item) =>
          permissions_id.filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuDesktop item={item} />
          ) : (
            ""
          ),
        )}

        <li className='divider'></li>
      </ul>
    </li>
  );
};

const MenuDesktop = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);
  return (
    <Container className='menu-desktop'>
      <div className='logo-panel'>
        <Link to='/'>
          <img
            src={`${URL_SERVER}images/system/logo-akunaipa.png`}
            alt='logo akunaipa'
          />
        </Link>
      </div>

      <div
        className='container-items list-group list-group-flush menuPanelEscritorio'
        id='menuPanelEscritorio'>
        {items.map((item) =>
          [1, 31].filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuDesktop item={item} />
          ) : (
            ""
          ),
        )}
        <SubMenu
          text='Usuarios'
          icon={faUserAlt}
          items={items}
          permissions_id={[43, 44, 45, 46, 47, 48]}
        />

        <SubMenu
          text='Publicaciones'
          icon={faList}
          items={items}
          permissions_id={[4, 5, 6, 7, 8]}
        />

        {items.map((item) =>
          [72, 28, 49, 39].filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuDesktop item={item} />
          ) : (
            ""
          ),
        )}
        <ItemMenuDesktop
          item={{
            icon: faSignOutAlt,
            text: "Cerrar sesión",
            rute: `${URL_PANEL}cerrar-sesion`,
            component: CloseSession,
          }}
        />
      </div>
    </Container>
  );
};

const MenuMovil = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);
  return (
    <ContainerMovil className={`${props.open ? `d-block` : `d-none`}`}>
      <div className='logo-panel'>
        <Link to='/'>
          <img
            src={`${URL_SERVER}images/system/logo-akunaipa.png`}
            alt='logo akunaipa'
          />
        </Link>
      </div>

      <div
        className='container-items list-group list-group-flush menuPanelEscritorio'
        id='menuPanelEscritorio'>
        {items.map((item) =>
          [1, 31].filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuDesktop item={item} />
          ) : (
            ""
          ),
        )}
        <SubMenu
          text='Usuarios'
          icon={faUserAlt}
          items={items}
          permissions_id={[43, 44, 45, 46, 47, 48]}
        />

        <SubMenu
          text='Publicaciones'
          icon={faList}
          items={items}
          permissions_id={[4, 5, 6, 7, 8]}
        />

        {items.map((item) =>
          [72, 28, 49, 39].filter((permission_id) => permission_id === item.id)
            .length !== 0 ? (
            <ItemMenuDesktop item={item} />
          ) : (
            ""
          ),
        )}
        <ItemMenuDesktop
          item={{
            icon: faSignOutAlt,
            text: "Cerrar sesión",
            rute: `${URL_PANEL}cerrar-sesion`,
            component: CloseSession,
          }}
        />
      </div>
    </ContainerMovil>
  );
};

const MenuPanel = (props) => {
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const { permissions } = getDataUser();
    const viewsMenu = rutesMenuPanel.filter((item) =>
      permissions.find((permission) => permission.id === item.id) ? item : null,
    );
    setItems(viewsMenu);
  }, []);
  return (
    <>
      <MenuFooterTwo open={open} setOpen={setOpen} />
      {/* <MenuFooter items={items} /> */}
      <MenuDesktop items={items} />
      <MenuMovil open={open} items={items} />
    </>
  );
};

export default MenuPanel;

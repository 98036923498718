/** @format */

import styled from "styled-components";
import LogoLittle from "../../atoms/images/logo-little";

const ContainerImg = styled.div`
  text-align: center;
  height: auto;
  width: ${(props) => props.width || 100}%;
  display: block;
`;

const LogoHeadForm = (props) => {
  return (
    <ContainerImg width={props.cWidth}>
      <LogoLittle width={props.iWidth} />
    </ContainerImg>
  );
};

export default LogoHeadForm;

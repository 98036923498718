import BasePublic from "../../organisms/bases/base-public";

import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { getFetch } from "../../../helper/fetchApi";
import { useEffect, useState } from "react";
import SubtitleCategorie from "../../atoms/texts/subtitle-categorie";
import CardPublication from "../../molecules/cards/card-publication";
import { Pagination } from "react-bootstrap";
const BoxPaginate = styled.div`
    display: flex;
    align-items: center;
    ul {
        margin: 0 auto;
    }
`;
const PublicationsCategorie = (props) => {
    console.log(useLocation());
    const { search, pathname } = useLocation();
    const [results, setResults] = useState({ data: [] });
    const [pagination, setPagination] = useState([]);
    useEffect(() => {
        getFetch(
            `publications/categorie${pathname}?imagespublication=true&municipalitie-department=true&favorites_count=true&categorie-parent_categorie=true&price_min=true&is_favorite_count=true&paginate=12`
        ).then((response) =>
            response.success ? setResults(response.data) : ""
        );
    }, []);

    const change = (page) => {
        getFetch(
            `publications/categorie${pathname}?imagespublication=true&municipalitie-department=true&favorites_count=true&categorie-parent_categorie=true&price_min=true&is_favorite_count=true&paginate=12&page=${page}`
        ).then((response) =>
            response.success ? setResults(response.data) : ""
        );
    };
    useEffect(() => {
        let newPagination = [];
        if (results.last_page >= 1) {
            for (let number = 1; number < results.last_page; number++) {
                newPagination = [
                    ...newPagination,
                    <Pagination.Item
                        key={number}
                        active={number === results.current_page}
                        onClick={() => change(number)}
                    >
                        {number}
                    </Pagination.Item>,
                ];
            }
            setPagination(newPagination);
        }
    }, [results]);

    return (
        <BasePublic title="Resultado" carousel formsearch>
            <div className="mt-5 mb-5">
                {/* {JSON.stringify(results)} */}
                <div className="row">
                    {results.data.map((publication) => (
                        <>
                            <CardPublication
                                className="col-lg-3"
                                publication={publication}
                            />
                        </>
                    ))}
                    <BoxPaginate className="col-lg-12  mt-3">
                        <Pagination>{pagination}</Pagination>
                    </BoxPaginate>
                </div>
            </div>
        </BasePublic>
    );
};

export default PublicationsCategorie;

import BasePublic from "../../organisms/bases/base-public";

import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { getFetch } from "../../../helper/fetchApi";
import { useEffect, useState } from "react";
import SubtitleCategorie from "../../atoms/texts/subtitle-categorie";
import CardPublication from "../../molecules/cards/card-publication";
const ResultSearch = (props) => {
    const { search } = useLocation();
    const [results, setResults] = useState([]);
    useEffect(() => {
        getFetch(`publications/search${search}`).then((response) =>
            response.success ? setResults(response.data) : ""
        );
    }, []);

    useEffect(() => {
        getFetch(`publications/search${search}`).then((response) =>
            response.success ? setResults(response.data) : ""
        );
    }, [search]);

    return (
        <BasePublic title="Resultado" carousel formsearch>
            <div className="mt-5 mb-5">
                {results.map((result) => (
                    <>
                        <SubtitleCategorie text={result.name} />
                        <div className="row">
                            {result.publications.map((publication) => (
                                <>
                                    <CardPublication
                                        className="col-lg-3"
                                        publication={publication}
                                    />
                                </>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </BasePublic>
    );
};

export default ResultSearch;

/** @format */

import React from "react";
import styled from "styled-components";

const ImageLittle = styled.img`
  width: ${(props) => (props.width ? props.width : 100)}%;
`;

const LogoLittle = (props) => {
  return (
    <ImageLittle
      width={props.width}
      src='./../../imgs/logo-akunaipa-min.png'
      alt='Logo Akunaipa'
    />
  );
};

export default LogoLittle;

/** @format */

import React from "react";
import Select from "react-select";
import styled from "styled-components";
import LabelInput from "../../atoms/labels/label-input";
import ValidationInputText from "../../atoms/texts/validation-input-text";

const control = {
  control: (base, state) => ({
    ...base,
    border: "0px solid black",
    boxShadow: "none",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  }),
};

const thema = {
  borderRadius: 5,
  borderColor: "hsl(0,0%,100%)",
  // spacing: { baseUnit: 2.2, controlHeight: 32, menuGutter: 8 },
};

const Container = styled.div`
  padding: 1% 2%;
`;

const InputSelectLabel = (props) => {
  const { select, label, className } = props;
  const {
    isSearchable,
    options = [],
    value,
    onChange,
    name,
    isMulti,
    closeMenuOnSelect,
    message,
  } = select;
  return (
    <Container className={className}>
      <LabelInput {...label} />
      <Select
        isSearchable={isSearchable || false}
        placeholder='Seleccionar'
        options={options}
        controlHeight='20'
        defaultValue={value}
        value={value}
        theme={thema}
        styles={control}
        onChange={onChange}
        name={name}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      <ValidationInputText message={message} />
    </Container>
  );
};

export default InputSelectLabel;

import React, { useState, useEffect } from "react";

import Select from "react-select";
import { getFetch, postFetch } from "../../../helper/fetchApi";
import GroupCardsActions from "../../organisms/cards/group-cards-actions";
import { Modal, Button } from "react-bootstrap";
import addValueSelect from "../../../helper/addValueSelect";
import alertIcon from "../../../helper/alerts/alert-icon";
import BasePanel from "../../organisms/bases/base-panel";
import LoadingForm from "../../molecules/loading/loading-form";
const RolesAndPermissions = () => {
    const [roles, setRoles] = useState([]);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roleView, setRoleView] = useState([]);
    const [permissions, setPermissions] = useState([]);
    // const [selected, setSelected] = useState([])
    const [form, setForm] = useState({});

    useEffect(() => {
        getFetch("role?count=true").then((response) => setRoles(response.data));
        getFetch("permission?order_by=ASC").then((response) =>
            setPermissions(addValueSelect(response.data, "name", "id"))
        );
    }, []);

    const handleClose = () => setShow(false);

    const listPermissions = (id) => {
        const role = roles.find((role) => role.id === id);
        setRoleView({ role: role });

        getFetch(`role/${role.id}/permissions`).then((response) => {
            const selected = response.data.map((permision) => ({
                label: permision.name,
                value: permision.id,
            }));
            setPermissions({
                ...permissions,
                value: [...selected],
            });
            setShow(true);
        });
    };

    const update = (e) => {
        e.preventDefault();

        const formulary = new FormData(e.target);
        const newCout = formulary.getAll("permission_id[]").length;
        setLoading(true);
        postFetch(`role/${roleView.role?.id}/permissions`, formulary).then(
            (response) => {
                if (response.success) {
                    alertIcon("permisos actualizados correctamente", "success");
                    setLoading(false);
                } else {
                    alertIcon("error al actualizar permisos", "error");
                }
            }
        );

        const newRoles = roles.map((rol) => {
            if (rol.id === roleView.role.id) {
                return {
                    ...rol,
                    permissions_count: newCout,
                };
            }

            return rol;
        });
        setRoles(newRoles);
    };

    return (
        <div>
            <BasePanel page="Roles y permisos">
                <GroupCardsActions
                    data={{ collection: roles }}
                    action={listPermissions}
                />

                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Permisos {roleView.role?.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={update} className="form-group">
                            <input
                                type="number"
                                hidden
                                value={roleView.role?.id}
                            />
                            <label>Seleccionar permisos a asignar.</label>
                            <Select
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                                closeMenuOnSelect={false}
                                defaultValue={permissions.value}
                                isMulti
                                onChange={(e) => {}}
                                name="permission_id[]"
                                options={permissions.options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            <LoadingForm loading={loading} />
                            <div className="text-center mt-2">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                >
                                    Guardar Cambios
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </BasePanel>
        </div>
    );
};

export default RolesAndPermissions;

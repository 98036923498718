import React, { Suspense, lazy } from "react";
import BasePanel from "../../organisms/bases/base-panel";

const FormEditPublication = lazy(() =>
    import("../../organisms/forms/form-edit-publication")
);

const EditPublication = (props) => {
    return (
        <div>
            <BasePanel page="Editar  publicación">
                <Suspense fallback={<div>cargando...</div>}>
                    <FormEditPublication {...props} />
                </Suspense>
            </BasePanel>
        </div>
    );
};

export default EditPublication;

/** @format */

import React from "react";
import styled from "styled-components";

const ValidationFormStyles = styled.div`
  color: ${(props) =>
    props.theme.colors[props.color] || props.theme.colors.secondary};
  position: relative;
  font-size: 13px;
  margin-bottom: 10px;
`;

const ValidationForm = (props) => {
  return (
    <ValidationFormStyles {...props}>{props.message}</ValidationFormStyles>
  );
};

export default ValidationForm;

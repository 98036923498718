import GroupCardsPublication from "../../organisms/cards/group-card-publication";
import SubtitleCategorie from "../../atoms/texts/subtitle-categorie";
import BasePublic from "../../organisms/bases/base-public";

import { getItemStorage } from "../../../helper/local-storage";
import { useEffect, useState } from "react";
const StartPublic = (props) => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getItemStorage("categories").then((response) => {
            if (response) {
                setCategories(response.list);
            }
        });
    }, []);
    return (
        <BasePublic carousel formsearch title="inicio ">
            {categories.map((categorie) => (
                <>
                    <SubtitleCategorie text={categorie.name} />
                    <GroupCardsPublication {...categorie} />
                </>
            ))}
        </BasePublic>
    );
};

export default StartPublic;

/** @format */

import React from "react";
import styled from "styled-components";
import InputFileImage from "../../atoms/inputs/input-file-image";
import LabelInput from "../../atoms/labels/label-input";
import { MixinMargins } from "../../../globalstyle/mixinstyle/MixinStyle";
import ImagePreviewChange from "../../../helper/image-preview-change";

const InputLabelStyle = styled(MixinMargins)`
  width: ${(props) => props.widthBox + "%" || "auto"};
  margin: 0 auto;
  padding: 2% 0%;
`;

const InputFileLabel = (props) => {
  return (
    <div p={props.p} className={props.className} widthBox={props.widthBox}>
      <LabelInput {...props.label} color={props.labelColor} />
      <InputFileImage
        color={props.color}
        image={props.image}
        onChange={ImagePreviewChange}
        order={props.order}
        placeholder={props.placeholder}
        message={props.message}
        name={props.name}
        validate={props.validate}
        value={props.value}
        visible={props.visible}
        onDelete={props.onDelete}
        idImage={props.idImage}
        idKey={props.idKey}
      />
    </div>
  );
};

export default InputFileLabel;

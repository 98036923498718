/** @format */

import { Pagination, Row } from "react-bootstrap";
import CardUser from "../../molecules/cards/card-user";
import { useEffect, useState } from "react";
import styled from "styled-components";
const BoxPaginate = styled.div`
  display: flex;
  align-items: center;
  ul {
    margin: 0 auto;
  }
`;
const GroupCardsUsers = (props) => {
  const [pagination, setPagination] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (props?.users?.data) {
      setUsers(props.users.data);
      let newPagination = [];
      if (props.users.last_page >= 1) {
        for (let number = 1; number < props.users.last_page; number++) {
          newPagination = [
            ...newPagination,
            <Pagination.Item
              key={number}
              active={number === props.users.current_page}
              onClick={() => props.change(number)}>
              {number}
            </Pagination.Item>,
          ];
        }
        setPagination(newPagination);
      }
    }
  }, [props.users]);
  return (
    <div className='row mt-3 '>
      {/* {JSON.stringify(props.users.data)} */}
      {users.map((service) => (
        <CardUser data={service} openModal={props.openModal} />
      ))}
      <BoxPaginate className='col-lg-12  mt-3'>
        <Pagination>{pagination}</Pagination>
      </BoxPaginate>
    </div>
  );
};

export default GroupCardsUsers;

/** @format */

import React from "react";
import styled from "styled-components";
import { avatar, user } from "../../../helper/infoUserAuth";

const BoxAvatar = styled.div`
  width: 100%;
  height: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  align-content: right;
  justify-content: flex-end;
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }
`;

const AvatarUser = (props) => {
  return (
    <BoxAvatar>
      <img
        src={
          avatar ||
          "https://www.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg"
        }
        alt={`avatar ${user || "usuario"}`}
      />
    </BoxAvatar>
  );
};

export default AvatarUser;

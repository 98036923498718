/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, Redirect } from "react-router-dom";
import { expresiones } from "../../../helper/expresiones";
import { createLocalStorage, getDataUser } from "../../../helper/auth-helpers";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import LogoHeadForm from "../../molecules/images/logo-header-form";
import { getFetch, postFetch } from "../../../helper/fetchApi";
import ValidationForm from "../../atoms/texts/validation-form";

const ContainerForm = styled.form`
  width: 100%;
  padding: 18% 0%;
  > div {
    background: ${(props) => props.theme.form.background};
    padding: 1% 2%;
    border-radius: 10px;
    margin: 0 auto;
    width: 40%;
  }
  @media (max-width: 840px) {
    padding: 40% 0%;
    > div {
      width: 90%;
      padding: 5% 3%;
    }
  }
  a,
  a:hover {
    text-decoration: none;
  }
`;

const MessageValidation = styled.p`
  padding-left: 10px;
`;

const FormChangePassword = (props) => {
  const [form, setForm] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const { token } = getDataUser();
    if (token) {
      getFetch("auth/verify").then((response) => {
        if (response.success) {
          createLocalStorage(response.data);
          setIsLogin(true);
        }
      });
    }
  }, []);

  const formSubmit = (e) => {
    e.preventDefault();

    const formulary = new FormData(e.target);

    if (form?.password_confirmation?.validate && form?.password?.validate) {
      postFetch(`auth/recover-password${search}`, formulary).then(
        (response) => {
          if (response.success) {
            alert("success");
          } else {
            alert("false");
          }
        },
      );
    } else {
      setForm({
        ...form,
        validateForm: {
          message: "Rellena el formulario Correctamente",
          color: "primary",
        },
      });
    }
  };

  const handleInputChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          message: expresiones.message[e.target.name],
        },
      });
    }
  };

  //   if (isLogin) {
  //     return <Redirect to='/panel' />;
  //   }
  return (
    <ContainerForm onSubmit={formSubmit}>
      <div>
        <LogoHeadForm
          cWidth={100} //contenedor
          iWidth={25} //imagen
        />
        {search}
        <InputTextLabel
          label={{
            text: "Contraseña",
            color: "tertiary",
          }}
          input={{
            onChange: handleInputChange,
            placeholder: "******",
            name: "password",
            type: "password",
            validate: form?.email?.validate,
          }}
          message={form.email?.message}
        />
        <InputTextLabel
          label={{
            text: "Confirmar Contraseña",
            color: "tertiary",
          }}
          input={{
            onChange: handleInputChange,
            placeholder: "*****",
            name: "password_confirmation",
            type: "password",
            validate: form?.password?.validate,
          }}
          message={form?.password?.message}
        />
        <MessageValidation
          as={ValidationForm}
          message={form?.validateForm?.message}
          color={form?.validateForm?.color}
        />
        <button className='btn btn-block btn-primary'>
          Actualizar Contraseña
        </button>
      </div>
    </ContainerForm>
  );
};

export default FormChangePassword;

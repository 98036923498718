/** @format */

const initFormCreatePublication = {
  email: {
    message: "",
    value: "",
    validate: false,
  },
  neighborhood: {
    message: "",
    value: "",
    validate: false,
  },
  observation: {
    message: "",
    value: "",
    validate: false,
  },
  categorie: {
    message: "",
    value: "",
    validate: false,
  },
  subcategorie: {
    message: "",
    value: "",
    validate: false,
  },
  title: {
    message: "",
    value: "",
    validate: false,
  },
  phone: {
    message: "",
    value: "",
    validate: false,
  },
  whatsapp: {
    message: "",
    value: "",
    validate: false,
  },
  indication: {
    message: "",
    value: "",
    validate: false,
  },
  description: {
    message: "",
    value: "",
    validate: false,
  },
  direction: {
    message: "",
    value: "",
    validate: false,
  },
  open: {
    message: "",
    value: "00:00",
    validate: false,
  },
  close: {
    message: "",
    value: "12:00",
    validate: false,
  },
  price_name_validate: {
    message: "",
    value: "",
    validate: false,
  },
  price_validate: {
    message: "",
    value: "",
    validate: false,
  },
  service: {
    message: "",
    value: "",
    validate: false,
  },
};

const initPais = [{ value: 1, label: "Colombia" }];

const initSelect = {
  options: [{ label: "", value: "" }],
  value: [{ label: "", value: "" }],
};
export { initFormCreatePublication, initPais, initSelect };

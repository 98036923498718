import styled, { css } from "styled-components";

const MixinMargins = styled.div`
  ${(props) =>
    props.m &&
    css`
      margin: ${(props) => props.m + "%"};
    `};

  ${(props) =>
    !props.m && props.mb && props.mt && !props.ml && !props.mr
      ? css`
          m: ${(props) => props.mt + "%"} ${(props) => props.mb + "%"};
        `
      : css`
          margin-top: ${(props) => (props.mt ? props.mt + "%" : "")};
          margin-bottom: ${(props) => (props.mb ? props.mb + "%" : "")};
          margin-left: ${(props) => (props.ml ? props.ml + "%" : "")};
          margin-right: ${(props) => (props.mr ? props.mr + "%" : "")};
        `};

  ${(props) =>
    props.p &&
    css`
      padding: ${(props) => props.p + "%"};
    `};

  ${(props) =>
    !props.p && props.pb && props.pt && !props.pl && !props.pr
      ? css`
          padding: ${(props) => props.pt + "%"} ${(props) => props.pb + "%"};
        `
      : css`
          padding-top: ${(props) => (props.pt ? props.pt + "%" : "")};
          padding-bottom: ${(props) => (props.pb ? props.pb + "%" : "")};
          padding-left: ${(props) => (props.pl ? props.pl + "%" : "")};
          padding-right: ${(props) => (props.pr ? props.pr + "%" : "")};
        `};
`;

export { MixinMargins };

/** @format */

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingForm = (props) => {
  return (
    <div className='text-center  mb-2'>
      {props.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
    </div>
  );
};

export default LoadingForm;

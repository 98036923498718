/** @format */

import React, { useEffect, useState } from "react";
import { getFetch } from "../../../helper/fetchApi";
import CardPublication from "../../molecules/cards/card-publication";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
const More = styled.div`
  text-align: center;
  button {
    width: 30%;
  }
  @media (max-width: 840px) {
    button {
      width: 100%;
    }
  }
`;
const GroupCardPublication = (props) => {
  const [publications, setPublications] = useState([]);
  const { name = "", url = null, slug } = props;
  useEffect(() => {
    getFetch(
      `publications/categorie/${slug}?&limit=16&municipalitie-department=true&price_min=true&payments_count=true&categorie-parent_categorie=true&favorites_count=true&publication_state=name&imagespublication=true`,
    ).then((response) => {
      if (response.success) {
        setPublications(response.data);
      }
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      control: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className='container-fluid'>
      <Carousel
        responsive={responsive}
        swipeable={true}
        autoPlaySpeed={1000}
        infinite={true}
        arrows={false}
        keyBoardControl={true}
        autoplay={true}
        draggable={true}
        showDots={false}>
        {publications.map((publication, key) => (
          <CardPublication key={key} publication={publication} />
        ))}
      </Carousel>
      <More>
        <Link to={`/${slug}`} className='btn btn-primary '>
          Ver más {name}
        </Link>
      </More>
    </div>
  );
};

export default GroupCardPublication;

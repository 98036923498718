/** @format */

import { getDate, getTime, differenceDates } from "./date-times";

const setItemStorage = (objeto) => {
  const createLocal = {
    ...objeto,
    created_at: {
      date: `${getDate()} ${getTime()}`,
      time: getTime(),
    },
  };

  window.localStorage.setItem("categories", JSON.stringify(createLocal));
  // pending update diff dates
};

const getItemStorage = async (item) => {
  if (window.localStorage.getItem(item)) {
    return JSON.parse(window.localStorage.getItem(item));
  } else {
    return false;
  }
};

const getItemStorageSpeed = (item) => {
  if (window.localStorage.getItem(item)) {
    return JSON.parse(window.localStorage.getItem(item));
  } else {
    return false;
  }
};

export { setItemStorage, getItemStorage, getItemStorageSpeed };

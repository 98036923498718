import BasePublic from "../../organisms/bases/base-public";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";

const Questions = (props) => {
    const { id, question, response } = props.question;
    return (
        <>
            <Accordion defaultActiveKey="0" className="mb-2">
                <h4 className="modal-title mb-2" id="myModalLabel">
                    {question}
                </h4>
                <Accordion.Toggle
                    as="div"
                    className="btn btn-primary"
                    variant="link"
                    eventKey="1"
                >
                    Saber más
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>{response}</Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </>
    );
};

const FrecuentQuestions = (props) => {
    const questions = [
        {
            id: 1,
            question: "1-¿Qué tipo de negocio puedo publicar en akunaipa.com?",
            response: (
                <div>
                    En <strong>akunaipa.com</strong> puedo publicar un negocio
                    relacionado con: a dónde dormir, a dónde comer, diversión,
                    transporte, guías locales, rutas turísticas, paseos, entre
                    las demás actividades de tipo turístico rural o sus
                    complementarias.
                </div>
            ),
        },
        {
            id: 2,
            question: "2- ¿Cuánto cuesta publicar mi negocio en akunaipa.com?",
            response: (
                <div>
                    Publicar en <strong>akunaipa.com</strong> es totalmente
                    gratuito..
                </div>
            ),
        },
        {
            id: 3,
            question: "3-¿Cómo publicar mí negocio en akunaipa.com?",
            response: (
                <div>
                    Publicar mi negocio en akunaipa.com es sencillo, solo debes
                    seguir estos 3 pasos:
                    <p>a. Toma 10 excelentes fotografías del negocio.</p>
                    <p>
                        b. Envía las fotos y la descripción del negocio al
                        correo contacto@akunaipa.com
                    </p>
                    <p>
                        c. Espera un poco. Sólo tardará 2 días en ser publicado
                        tu negocio.
                    </p>
                </div>
            ),
        },
        {
            id: 4,
            question:
                "4- ¿Qué descripción debo adjuntar al correo contacto@akunaipa.com para publicar mi negocio?",
            response: (
                <>
                    <p>Siga este breve ejemplo:</p>
                    <p>
                        <strong>Nombre:</strong> Nombre del sitio.
                    </p>
                    <p>
                        <strong>Descripción:</strong> Número de habitaciones
                        disponibles con camas dobles y sencillas, ventilador,
                        desayuno incluido, parqueadero, se permiten mascotas,
                        wifi, baño privado, nevera en la habitación, televisión
                        por cable, áreas para niños, cama para niños, piscina,
                        sala de conferencias, biblioteca, mirador, hamacas,
                        sitio para camping, pesca deportiva, avistamiento de
                        aves, indica experiencias como por ejemplo recolección
                        de café, caminatas, cascadas, cabalgata, entre otras.
                        (Incluye todos los servicios).
                    </p>
                    <p>
                        <strong>Cómo llegar:</strong> Indique de manera
                        detallada como llegar. Por ejemplo: Hospedaje Doña Rita,
                        Km 1 vía La Ceiba – Ibagué Tolima. 100 metros adelante
                        del monumento a la Virgen. Portón amarillo a mano
                        derecha. O escriba una dirección si la tiene.
                    </p>
                    <p>
                        <strong>Página web o sitio en redes sociales:</strong>{" "}
                        Opcional
                    </p>
                    <p>
                        <strong>Celular de Contacto:&nbsp;</strong>
                        Números de contacto. Si no hay buena señal, recuerda
                        indicar la forma de contacto, mensaje de texto,
                        whatsaap, correo electrónico, etc.
                    </p>
                    <p>
                        <strong>Correo electrónico:</strong> Correo de contacto
                        (opcional)
                    </p>
                    <p>
                        <strong>Nombre de contacto (opcional):</strong> A las
                        personas les gusta sentirse en familia y preguntar por
                        alguien.
                    </p>
                    <p>
                        <strong>Adjunte fotografías de su negocio:</strong>
                        Tenga en cuenta que las fotografías deben ser de alta
                        calidad. Esta es su primera impresión. Tómelas en alta
                        resolución. Recuerde ser el propietario de las
                        fotografías.
                    </p>
                    <p>
                        Al enviar las fotografías a akunaipa.com usted acepta
                        las políticas de derechos de autor del territorio
                        Colombiano e internacional. Y permite el derecho a
                        www.akunaipa.com&nbsp; a publicarlas en su portal web,
                        redes sociales, aplicaciones u otros medios
                        audiovisuales, digitales o impresos con el fin de
                        promover el turismo.
                    </p>
                </>
            ),
        },
    ];

    return (
        <BasePublic title="Preguntas frecuentes" carousel formsearch>
            <div className="container mt-3 mb-5">
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 col-md-offset-3 text-center colorlib-heading animate-box fadeInUp animated-fast">
                        <h2>Preguntas Frecuentes</h2>
                    </div>
                </div>
                {questions.map((question) => (
                    <Questions question={{ ...question }} />
                ))}
            </div>
        </BasePublic>
    );
};

export default FrecuentQuestions;

import FormLogin from "../../organisms/forms/form-login";
import BasePublic from "../../organisms/bases/base-public";

const Login = (props) => {
    return (
        <BasePublic title="Entrar - akunaipa">
            <FormLogin />
        </BasePublic>
    );
};

export default Login;

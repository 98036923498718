/** @format */

import React, { useEffect, useState } from "react";

import styled from "styled-components";

// import MenuAdmin from "../menus/menu-admin";
// import MenuClient from "../menus/menu-client";
import MenuPanel from "../menus/menu-panel";
import HeadNavPanel from "../navbars/head-nav-panel";

const ContainerInicio = styled.span`
  display: grid;
  grid-template-columns: 18% 82%;
`;

const Contenido = styled.div`
  width: 100%;
  padding: 7% 3% 3% 3%;
`;

// const Menu = user.map((res) => {
//   if (rute[res.user].user.active) {
//     return res.menu;
//   }
// });

// console.log(Menu);

// let Menu = "";
// if (rute.user.user.active) {
//   Menu = MenuClient;
// }

// if (rute.user.admin.active) {
//   Menu = MenuAdmin;
// }

// if (rute.user.admin.active) {
//   Menu = MenuAdmin;
// }
// const Main = () => {
//   const user = [
//     {
//       rol: "admin",
//       menu: MenuAdmin,
//     },
//     {
//       rol: "user",
//       menu: MenuClient,
//     },
//     {
//       rol: "moderator",
//       menu: MenuClient,
//     },
//     {
//       rol: "client",
//       menu: MenuClient,
//     },
//   ];

//   const [View, setView] = useState();

//   useEffect(() => {
//     user.map((current) => {
//       if (currentUser.user === current.rol) {
//         return setView(current.menu);
//       }
//     });
//   }, []);

//   return View;
// };

const BaseProfile = (props) => {
  return (
    <ContainerInicio>
      <div>
        <MenuPanel />
      </div>
      <div>
        <HeadNavPanel page={props.page} />
        <Contenido>{props.children}</Contenido>
      </div>
    </ContainerInicio>
  );
};

export default BaseProfile;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

*{
 border: 0;
 margin: 0;
 box-sizing: border-box;

}
body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

a {
    text-decoration: none;
    font-family: 'Nunito Sans', sans-serif;
}

ul, li {
    list-style: none;
}

input:focus, button:focus, select:focus, textarea:focus {
    outline: none;
}
`;

export { GlobalStyle };

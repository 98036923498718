import BasePublic from "../../organisms/bases/base-public";
import FormChangePassword from "../../organisms/forms/form-change-password";

const ChangePassword = (props) => {
    return (
        <BasePublic title="Cambiar contraseña - akunaipa">
            <FormChangePassword />
        </BasePublic>
    );
};

export default ChangePassword;

/** @format */

import { Modal, Button, Row } from "react-bootstrap";
import { useState } from "react";
import InputFileLabel from "../../molecules/inputs/input-file-label";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import styled from "styled-components";
import { postFetch, putFetch } from "./../../../helper/fetchApi";
import BaseModal from "../../atoms/modals/base-modal";
import swal from "sweetalert";

const ModalCreateService = (props) => {
  const { form, setForm } = props;
  // const form = props.form;
  const { openModal, setOpenModal } = props.modal;

  const change = (e) => {
    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
      },
    });
  };

  const update = (e) => {
    e.preventDefault();
    const formulary = new FormData(e.target);
    postFetch(`services/update`, formulary).then((response) =>
      response.success
        ? swal("Servicio actualizado correctamente", { icon: "success" })
        : null,
    );
  };
  const create = (e) => {
    e.preventDefault();
    const formulary = new FormData(e.target);
    postFetch(`services`, formulary).then((response) =>
      response.success
        ? swal("Servicio creado correctamente", { icon: "success" })
        : null,
    );
  };
  return (
    <BaseModal
      modal={{ ...props.modal }}
      form={{ ...form }}
      actions={{ update: update, create: create }}>
      <InputTextLabel
        label={{
          text: "Nombre Servicio",
        }}
        input={{
          placeholder: "Ingresar Nombre",
          name: "name",
          onChange: change,
          value: form?.name?.value,
        }}
      />

      <InputFileLabel
        label={{
          text: "Logo Servicio",
        }}
        name='img'
        widthBox='35'
        order={1}
        image={form?.img?.value}
      />
      <div className='text-center mt-3'>
        <button className='btn btn-primary' type='submit'>
          {form?.edit ? "Actualizar servicio" : "Crear Servicio "}
        </button>
      </div>
    </BaseModal>
  );
};

export default ModalCreateService;

/** @format */

import { URL_API } from "../const/const";

const removeToken = () => {
  window.localStorage.removeItem("USER");
  window.localStorage.removeItem("TOKEN_KEY");
  return true;
};

const getDataUser = () => {
  const user = JSON.parse(window.localStorage.getItem("USER"));
  const token = window.localStorage.getItem("TOKEN_KEY");
  const clearUser = { ...user };
  delete clearUser["role"];
  delete clearUser["role_id"];
  delete clearUser["user_state_id"];
  delete clearUser["rol"];
  return user
    ? {
        login: true,
        role: user.roles[0].id,
        permissions: user.roles[0].permissions || [],
        token: token,
        user: clearUser,
      }
    : {
        login: false,
        role: false,
        permissions: [],
        token: false,
      };
};

const createLocalStorage = (dataUser, user = false) => {
  switch (user) {
    case "user":
      window.localStorage.setItem("USER", JSON.stringify(dataUser.user));

      break;

    default:
      window.localStorage.setItem("TOKEN_KEY", dataUser.token);
      window.localStorage.setItem("USER", JSON.stringify(dataUser.user));
      break;
  }
};

const headerAutorization = (method, datos) => {
  const { token } = getDataUser();

  const header = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      // "Content-Type": "application/json",
    },
    body: datos,
  };

  return header;
};

const sessionVerify = async () => {
  const { token } = getDataUser();
  const header = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`${URL_API}auth/verify`, header);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }

  removeToken();

  return false;
};

export {
  removeToken,
  createLocalStorage,
  headerAutorization,
  sessionVerify,
  getDataUser,
};

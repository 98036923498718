/** @format */

const expresiones = {
  validate: {
    email: new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/),
    password: new RegExp(/[0-9,A-Za-z]{5,20}/),
    user: new RegExp(/[0-9,A-Za-z]{5,20}/),
    name: new RegExp(/[0-9,A-Za-z]{5,20}/),
    password_confirmation: new RegExp(/[0-9,A-Za-z]{5,20}/),
    neighborhood: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{2,20}$/),
    direction: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{2,20}$/),
    title: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{2,20}$/),
    theme: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{2,20}$/),
    phone: new RegExp(/[0-9]{5,8}/),
    whatsapp: new RegExp(/[0-9]{10}/),
    indication: new RegExp(/[0-9,A-Za-z]{5,20}/),
    observation: new RegExp(/[0-9,A-Za-z]{5,20}/),
    description: new RegExp(/[0-9,A-Za-z]{5,20}/),
    nameuser: new RegExp(/[0-9,A-Za-z ]{4,20}/),
    lastname: new RegExp(/[0-9,A-Za-z ]{2,20}/),
    open: new RegExp(/^(0[0-2]|1[0-9]|2[0-3]):[0-5][0-9]$/),
    close: new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
    price: new RegExp(/[0-9]{5,20}/),
    price_name: new RegExp(/[A-Za-z]{5,20}/),
    message: new RegExp(/[0-9,A-Za-z ]{2,20}/),
  },
  message: {
    email: "Correo no valido",
    password: "Contraseña no valida",
    password_confirmation: "las contraseñas no coinciden",
    user: "Usuario no valido",
    name: "Nombre no valido",
    theme: "Nombre no valido",
    neighborhood: "Barrio no valido",
    direction: "Direccion no valido",
    title: "Titulo no valido",
    phone: "Telefono no valido",
    whatsapp: "Whatsapp no valido",
    indication: "Indicaciones no valida",
    description: "Descripcion no valida",
    observation: "Observaciones no validas",
    nameuser: "Nombre no valido",
    lastname: "Apellido no valido",
    open: "Apertura no valida",
    close: "Cierre no valido",
    price: "precio no valido",
    price_name: "Nombre no valido",
    message: "Mensaje no valido",
  },
};

export { expresiones };

/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtomPrimary from "../../molecules/buttons/button-primary";
import InformationUbication from "./sectionsform/information-location";
import SegmentPlace from "./sectionsform/segment-place";
import TitlePublication from "./sectionsform/title-publication";
import BusinessHours from "./sectionsform/business-hours";
import ContactInformation from "./sectionsform/contact-information";
import Descriptions from "./sectionsform/descriptions";
import ImagesPublications from "./sectionsform/images-publication";
import { ContainerForm } from "./style/styleForm";
import {
  getFetch,
  postCreatePublish,
  postFetch,
} from "../../../helper/fetchApi";
import Icon from "../../atoms/icons/icon";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CreatePrecios from "./sectionsform/create-prices";
import swal from "sweetalert";
import InputTextLabel from "../../molecules/inputs/input-text-label";
import { getDataUser } from "../../../helper/auth-helpers";
import { expresiones } from "../../../helper/expresiones";
import swall from "sweetalert";
const MessageError = styled.ul`
  color: ${(props) => props.theme.colors.primaryError};
  display: grid;
  grid-template-columns: repeat(3, 33.3%);

  li {
    font-size: 13px;
  }
  li span {
    margin-right: 5px;
  }
`;

const FormEditProfile = (props) => {
  const [form, setForm] = useState({});

  const submit = (e) => {
    e.preventDefault();
    const formulary = new FormData(e.target);

    postFetch("user/update", formulary).then((response) => {
      if (response.success) {
        window.localStorage.setItem("USER", JSON.stringify(response.data));
        swall("Información actualizada Correctamente", { icon: "success" });
      } else {
        const keys = Object.keys(response.data);
        keys.map((key) =>
          setForm({
            ...form,
            [key]: { ...form[key], message: response.data[key][0] },
          }),
        );
      }
    });
  };

  useEffect(() => {
    const { user } = getDataUser();
    const keys = Object.keys(user);

    keys.map((key) => {
      if (Object.keys(expresiones.validate).find((n) => n === key)) {
        user[key] = {
          value: user[key],
          validate: expresiones?.validate[key].test(user[key]),
        };
      } else {
        console.log("no existes");
        delete user[key];
      }
    });
    setForm(user);
  }, []);

  const onChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validate: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          message: expresiones.message[e.target.name],
          validate: false,
        },
      });
    }
  };

  return (
    <ContainerForm
      onSubmit={submit}
      id='create-public'
      encType='multipart/form-data'>
      <div className='row'>
        <InputTextLabel
          label={{
            text: "Nombres",
          }}
          input={{
            name: "name",
            onChange: onChange,
            placeholder: "Nombre",
            value: form.name?.value,
          }}
          message={form.name?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "Apellidos",
          }}
          input={{
            name: "lastname",
            onChange: onChange,
            placeholder: "Apellidos",
            value: form.lastname?.value,
          }}
          message={form.lastname?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "Telefono",
          }}
          input={{
            type: "tel",
            name: "phone",
            onChange: onChange,
            placeholder: "Telefono",
            value: form.phone?.value,
          }}
          message={form.phone?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "Usuario",
          }}
          input={{
            type: "text",
            name: "user",
            onChange: onChange,
            placeholder: "Usuario123",
            value: form.user?.value,
          }}
          message={form.user?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "Correo",
          }}
          input={{
            type: "email",
            name: "email",
            onChange: onChange,
            placeholder: "example@gmail.com",
            value: form.email?.value,
          }}
          message={form.email?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "whatsapp",
          }}
          input={{
            type: "tel",
            name: "whatsapp",
            onChange: onChange,
            placeholder: "318440****",
            value: form.whatsapp?.value,
          }}
          message={form.whatsapp?.message}
          className='col-lg-4'
        />

        <InputTextLabel
          label={{
            text: "Contraseña actual",
          }}
          input={{
            type: "password",
            name: "password",
            onChange: onChange,
            placeholder: "*****",
            value: form.password?.value,
          }}
          message={form.password?.message}
          className='col-lg-4'
        />
      </div>

      <div className='text-center'>
        <button type='submit' className='btn btn-primary'>
          Actualizar información
        </button>
      </div>
    </ContainerForm>
  );
};

export default FormEditProfile;

/** @format */

const ImagePreviewChange = (e) => {
  const target = e.target;

  console.log(target);

  const number = target.getAttribute("data-order");

  const preview = document.querySelector(`[data-preview='${number}']`);

  var file = e.target.files[0];

  var reader = new FileReader();

  reader.onloadend = function () {
    preview.src = reader.result;
  };

  reader.readAsDataURL(file);
};

export default ImagePreviewChange;

/** @format */

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./control.css";
import styled from "styled-components";

const Images = styled.img`
  width: 100%;
`;

const Carousel = (props) => {
  let amount = 2;
  if (props.type) {
    amount = 3;
  }
  var images = props.items.filter((x, key) => key < amount);
  const items = images.map((item, index) => {
    const style = { height: 150 };
    return <Images style={style} className='item' alt='' lazy src={item.url} />;
  });

  return (
    <AliceCarousel
      autoHeight
      infinite
      mouseTracking
      items={items}
      autoplay
      disableButtonsControls
    />
  );
};
// disableDotsControls

export default Carousel;

/** @format */

import React, { useEffect, useState } from "react";
import InputSelectLabel from "../../../molecules/inputs/input-select-label";
import { expresiones } from "../../../../helper/expresiones";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import InputTimeLabel from "../../../molecules/inputs/input-time-label";
import { initFormCreatePublication } from "../stateinitialforms/init-form-create-publication";
import validateInputText from "../../../../helper/validate-input-text";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  @media (max-width: 840px) {
    display: block;
  }
`;

const BusinessHours = ({ publication }) => {
  const startServiceContinuos = {
    options: [
      { label: "Si", value: 1 },
      { label: "No", value: 0 },
    ],
    value: { label: "Si", value: 1 },
  };
  const [form, setForm] = useState();
  const [validate, setValidate] = useState(initFormCreatePublication);
  const [disabled, setDisable] = useState(true);
  const [service, setService] = useState(startServiceContinuos);
  const handledChange = (e) => {
    if (expresiones.validate[e.target.name].test(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          validation: true,
        },
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: {
          value: e.target.value,
          message: expresiones.message[e.target.name],
        },
      });
    }
  };

  const handledChangeService = (e) => {
    setService({
      ...service,
      value: { label: e.label, value: e.value },
    });
    horary(e.value);
  };

  const horary = (value) => {
    if (value) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  useEffect(() => {
    if (publication) {
      const { continuos_service, open, close } = publication;
      setForm({
        open: {
          value: open,
        },
        close: {
          value: close,
        },
      });
      const typeService = parseInt(continuos_service)
        ? { label: "Si", value: 1 }
        : { label: "No", value: 0 };
      setService({
        ...service,
        value: [typeService],
      });
      horary(continuos_service);
    }
  }, [publication]);

  return (
    <>
      <SubtitleForm text='Horarios de atencion' />
      <Container>
        <InputSelectLabel
          label={{
            text: "Servico Continuo",
          }}
          select={{
            ...service,
            name: "continuos_service",
            onChange: handledChangeService,
          }}
        />
        <InputTimeLabel
          label={{
            text: "Apertura",
          }}
          input={{
            min: "00:00",
            max: "12:00",
            value: form?.open?.value,
            onChange: handledChange,
            name: "open",
            disabled: disabled,
          }}
        />
        <InputTimeLabel
          label={{
            text: "Cierre",
          }}
          input={{
            min: "12:00",
            max: "24:00",
            label: "Cierre",
            value: form?.close?.value,
            onChange: handledChange,
            name: "close",
            disabled: disabled,
          }}
        />
      </Container>
    </>
  );
};

export default BusinessHours;

/** @format */

import styled from "styled-components";
import SubtitleForm from "../../../atoms/texts/subtitle-form";
import formatCurrency from "../../../../helper/format-currency";

const Table = styled.table`
  white-space: nowrap;
  .table-actions {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`;
const CreatePrices = (props) => {
  const { title, modal, setModal, prices } = props;
  const { listPrices, setListPrices } = prices;

  return (
    <>
      <SubtitleForm text={title || "Crear Precios"} />
      {listPrices.map(({ name, price, id }) => (
        <>
          <input type='text' hidden name='price_id[]' value={id} />
          <input type='text' hidden name='price_name[]' value={name} />
          <input type='text' hidden name='price[]' value={price} />
        </>
      ))}
      {/* {JSON.stringify(props.publication.prices)} */}
      <div className='col-lg-12 text-center'>
        <div
          className='btn btn-primary'
          onClick={() => setModal({ open: true, edit: false })}>
          Crear Precios
        </div>
      </div>
      <div className='table-responsive '>
        <Table className='table'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre precio</th>
              <th>Monto</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {listPrices.map(({ name, price, id }, key) => (
              <tr className='key-edit-price'>
                <td>{key + 1}</td>
                <td>{name}</td>
                <td>{formatCurrency(price)}</td>
                <td className='table-actions'>
                  <div className=' '>
                    <div
                      className=' btn btn-warning'
                      onClick={() =>
                        setModal({
                          ...modal,
                          edit: true,
                          key: key,
                          id: id,
                          open: true,
                        })
                      }>
                      Editar
                    </div>
                  </div>
                  <div
                    className=''
                    onClick={() => props.actions.delete(price.id)}>
                    <div
                      className=' btn btn-danger'
                      onClick={() => props.actions.delete(price.id)}>
                      Eliminar
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default CreatePrices;
